import "react-dropdown/style.css";
import "./NewContacts.css";

import { Button, Col, FormControl, FormGroup, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";
import CSVReader from "react-csv-reader";
import Dropdown from "react-dropdown";
import IosRefresh from "react-ionicons/lib/IosRefresh";
import MdArrowBack from "react-ionicons/lib/MdArrowBack";
import MdArrowForward from "react-ionicons/lib/MdArrowForward";
import ViewCampaign from "./ViewCampaign";

export default function NewContacts(props) {
  const [campaign, setCampaign] = useState(null);
  const [contacts, setContacts] = useState(null);
  const [matching, setMatching] = useState([]);
  const [hasLinkedIn, setHasLinkedIn] = useState(false);
  const [finishedMapping, setFinishedMapping] = useState(false);
  const [uploadReady, setUploadReady] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [options, setOptions] = useState([
    "email",
    "linkedin",
    "firstName",
    "lastName",
    "position",
    "company",
    "website",
    "twitter",
    "location",
    "custom1",
    "custom2",
    "custom3",
    "custom4",
    "custom5",
    "custom6",
    "custom7",
    "custom8",
    "custom9",
    "custom10",
  ]);
  const [mappedContacts, setMappedContacts] = useState(null);
  const [variables, setVariables] = useState(null);
  const [previewIndex, setPreviewIndex] = useState(0);
  const [connectionRequest, setConnectionRequest] = useState("");
  const [hasFirstFollowup, setHasFirstFollowup] = useState(false);
  const [firstFollowUpText, setFirstFollowUpText] = useState("");
  const [hasSecondFollowup, setHasSecondFollowup] = useState(false);
  const [secondFollowUpText, setSecondFollowUpText] = useState("");
  const [firstFollowUpDays, setFirstFollowUpDays] = useState(1);
  const [secondFollowUpDays, setSecondFollowUpDays] = useState(1);
  const [hasThirdFollowup, setHasThirdFollowup] = useState(false);
  const [thirdFollowUpDays, setThirdFollowUpDays] = useState(1);
  const [thirdFollowUpText, setThirdFollowUpText] = useState("");
  const [hasFourthFollowup, setHasFourthFollowup] = useState(false);
  const [fourthFollowUpText, setFourthFollowUpText] = useState("");
  const [fourthFollowUpDays, setFourthFollowUpDays] = useState(1);
  const [hasFifthFollowup, setHasFifthFollowup] = useState(false);
  const [fifthFollowUpText, setFifthFollowUpText] = useState("");
  const [fifthFollowUpDays, setFifthFollowUpDays] = useState(1);
  const [hasSixthFollowup, setHasSixthFollowup] = useState(false);
  const [sixthFollowUpText, setSixthFollowUpText] = useState("");
  const [sixthFollowUpDays, setSixthFollowUpDays] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [templates, setTemplates] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [hasConnectionRequest, setHasConnectionRequest] = useState(false);

  useEffect(() => {
    function loadCampaign() {
      return API.get("campaigns", `/campaigns/${props.campaignId}`);
    }

    function loadTemplates() {
      return API.get("templates", "/templates");
    }

    async function onLoad() {
      try {
        const campaign = await loadCampaign();
        const templates = await loadTemplates();
        const templateOptions = [];
        for (let i in templates) {
          templateOptions.push({
            value: templates[i].templateId,
            label: templates[i].title,
            description: templates[i].description,
            connectionRequestText: templates[i].connectionRequestText,
            hasFirstFollowup: templates[i].hasFirstFollowup,
            firstFollowUpDays: templates[i].firstFollowUpDays,
            firstFollowUpText: templates[i].firstFollowUpText,
            hasSecondFollowup: templates[i].hasSecondFollowup,
            secondFollowUpDays: templates[i].secondFollowUpDays,
            secondFollowUpText: templates[i].secondFollowUpText,
            hasThirdFollowup: templates[i].hasThirdFollowup,
            thirdFollowUpDays: templates[i].thirdFollowUpDays,
            thirdFollowUpText: templates[i].thirdFollowUpText,
            fourthFollowUpDays: templates[i].fourthFollowUpDays,
            hasFourthFollowup: templates[i].hasFourthFollowup,
            fourthFollowUpText: templates[i].fourthFollowUpText,
            fifthFollowUpDays: templates[i].fifthFollowUpDays,
            hasFifthFollowup: templates[i].hasFifthFollowup,
            fifthFollowUpText: templates[i].fifthFollowUpText,
            sixthFollowUpDays: templates[i].sixthFollowUpDays,
            hasSixthFollowup: templates[i].hasSixthFollowup,
            sixthFollowUpText: templates[i].sixthFollowUpText,
            templateId: templates[i].templateId,
          });
        }
        setPreviewIndex(0);
        setTemplates(templateOptions.reverse());
        setSelectedTemplate(templateOptions[0]);
        setConnectionRequest(templateOptions[0].connectionRequestText);
        setHasFirstFollowup(templateOptions[0].hasFirstFollowup);
        setFirstFollowUpDays(templateOptions[0].firstFollowUpDays);
        setFirstFollowUpText(templateOptions[0].firstFollowUpText);
        setHasSecondFollowup(templateOptions[0].hasSecondFollowup);
        setSecondFollowUpDays(templateOptions[0].secondFollowUpDays);
        setSecondFollowUpText(templateOptions[0].secondFollowUpText);
        setHasThirdFollowup(templateOptions[0].hasThirdFollowup);
        setThirdFollowUpDays(templateOptions[0].thirdFollowUpDays);
        setThirdFollowUpText(templateOptions[0].thirdFollowUpText);
        setHasFourthFollowup(templateOptions[0].hasFourthFollowup);
        setFourthFollowUpDays(templateOptions[0].fourthFollowUpDays);
        setFourthFollowUpText(templateOptions[0].fourthFollowUpText);
        setHasFifthFollowup(templateOptions[0].hasFifthFollowup);
        setFifthFollowUpDays(templateOptions[0].fifthFollowUpDays);
        setFifthFollowUpText(templateOptions[0].fifthFollowUpText);
        setHasSixthFollowup(templateOptions[0].hasSixthFollowup);
        setSixthFollowUpDays(templateOptions[0].sixthFollowUpDays);
        setSixthFollowUpText(templateOptions[0].sixthFollowUpText);
        setHasConnectionRequest(true);
        setCampaign(campaign);
        setIsLoading(true);
      } catch (e) {
        console.log(e);
      }
    }

    onLoad();
  }, [props.campaignId, mappedContacts, previewIndex]);

  function validateForm() {
    return true;
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsUploading(true);

    const updatedCampaign = campaign;
    updatedCampaign["contacts"] = mappedContacts.length;
    await saveCampaign(updatedCampaign);

    const contactsToUpload = [];

    for (let index in mappedContacts) {
      const contact = mappedContacts[index];
      if (contact["linkedinUsername"] !== "") {
        const new_contact = {};
        new_contact["campaignId"] = props.campaignId;
        new_contact["accountId"] = campaign.accountId;
        new_contact["campaignUsername"] = campaign.username;
        new_contact["campaignSlug"] = campaign.slug;
        new_contact["linkedinUrl"] = contact["linkedin"];
        new_contact["linkedinUsername"] = contact["linkedinUsername"];
        const fields = [
          "email",
          "linkedin",
          "firstName",
          "lastName",
          "position",
          "company",
          "location",
          "custom1",
          "custom2",
          "custom3",
          "custom4",
          "custom5",
          "custom6",
          "custom7",
          "custom8",
          "custom9",
          "custom10",
        ];
        for (let fieldIdx in fields) {
          const fieldValue = fields[fieldIdx];
          if (fieldValue in contact)
            new_contact[fieldValue] = contact[fieldValue];
        }
        new_contact["connectionRequestText"] = parseCopy(
          connectionRequest,
          index
        );
        new_contact["hasConnectionRequest"] = hasConnectionRequest;
        new_contact["hasFirstFollowup"] = hasFirstFollowup;
        new_contact["firstFollowUpDays"] = firstFollowUpDays;
        new_contact["hasSecondFollowup"] = hasSecondFollowup;
        new_contact["secondFollowUpDays"] = secondFollowUpDays;
        new_contact["hasThirdFollowup"] = hasThirdFollowup;
        new_contact["thirdFollowUpDays"] = thirdFollowUpDays;
        new_contact["hasFourthFollowup"] = hasFourthFollowup;
        new_contact["fourthFollowUpDays"] = fourthFollowUpDays;
        new_contact["hasFifthFollowup"] = hasFifthFollowup;
        new_contact["fifthFollowUpDays"] = fifthFollowUpDays;
        new_contact["hasSixthFollowup"] = hasSixthFollowup;
        new_contact["sixthFollowUpDays"] = sixthFollowUpDays;

        if (hasFirstFollowup)
          new_contact["firstFollowUpText"] = parseCopy(
            firstFollowUpText,
            index
          );
        new_contact["secondFollowUpDays"] = secondFollowUpDays;
        new_contact["templateId"] = selectedTemplate.value;
        if (hasSecondFollowup)
          new_contact["secondFollowUpText"] = parseCopy(
            secondFollowUpText,
            index
          );
        if (hasThirdFollowup)
          new_contact["thirdFollowUpText"] = parseCopy(
            thirdFollowUpText,
            index
          );
        if (hasFourthFollowup)
          new_contact["fourthFollowUpText"] = parseCopy(
            fourthFollowUpText,
            index
          );
        if (hasFifthFollowup)
          new_contact["fifthFollowUpText"] = parseCopy(
            fifthFollowUpText,
            index
          );
        if (hasSixthFollowup)
          new_contact["sixthFollowUpText"] = parseCopy(
            sixthFollowUpText,
            index
          );

        contactsToUpload.push(new_contact);
      }
    }

    var i,
      j,
      contacts,
      chunk = 25;
    for (i = 0, j = contactsToUpload.length; i < j; i += chunk) {
      contacts = contactsToUpload.slice(i, i + chunk);
      await createContact({ contacts });
    }

    setIsUploading(false);
    setUploadComplete(true);
  }

  function saveCampaign(updatedCampaign) {
    return API.put("campaigns", `/campaigns/${props.campaignId}`, {
      body: updatedCampaign,
    });
  }

  function createContact(contacts) {
    return API.post("contacts", "/contacts", {
      body: contacts,
    });
  }

  function handleForce(data) {
    const contacts = data;
    setContacts(contacts);
  }

  function _onSelect(fieldIndex, fieldName) {
    const new_matching = matching;
    const new_options = [
      "email",
      "linkedin",
      "firstName",
      "lastName",
      "position",
      "company",
      "location",
      "custom1",
      "custom2",
      "custom3",
      "custom4",
      "custom5",
      "custom6",
      "custom7",
      "custom8",
      "custom9",
      "custom10",
    ];

    for (let i in matching) {
      if (fieldIndex === matching[i].fieldIndex) {
        new_matching.splice(i, 1);
      }
    }

    if (fieldName.value !== "--") {
      new_matching.push({
        fieldIndex: fieldIndex,
        fieldName: fieldName.value,
      });
    }

    // for(let new_matching_index in new_matching) {
    //     new_options.splice(options.indexOf(new_matching[new_matching_index].fieldName), 1)
    // }

    matching.filter((match) => match.fieldName === "linkedin").length === 1
      ? setHasLinkedIn(true)
      : setHasLinkedIn(false);
    setOptions(new_options);
    setMatching(new_matching);
  }

  function handleMatchingClick() {
    setFinishedMapping(true);
    const new_contacts = [];
    for (let contact_index in contacts) {
      const contact = {};
      for (let matching_index in matching) {
        contact[matching[matching_index]["fieldName"]] =
          contacts[contact_index][matching[matching_index]["fieldIndex"]];
      }
      if (contact["linkedin"] && contact["linkedin"] !== "") {
        let editedText = contact["linkedin"];
        if (editedText.slice(-1) === "/") editedText = editedText.slice(0, -1);
        const linkedinUsername = editedText.split("/")[
          editedText.split("/").length - 1
        ];
        if (linkedinUsername && linkedinUsername.length > 3) {
          if (linkedinUsername.includes("?")) {
            contact["linkedinUsername"] = linkedinUsername.split("?")[0];
          } else {
            contact["linkedinUsername"] = linkedinUsername;
          }

          new_contacts.push(contact);
        }
      }
    }
    let coolContacts = new_contacts.slice(1, new_contacts.length);
    setMappedContacts(coolContacts);
    const starting_vars = [];
    for (let key in new_contacts[previewIndex]) {
      starting_vars.push({
        label: key,
        value: new_contacts[previewIndex][key],
      });
    }
    setVariables(starting_vars);
  }

  function handleFirstFollowup() {
    setHasFirstFollowup(!hasFirstFollowup);
  }

  function handleSecondFollowup() {
    setHasSecondFollowup(!hasSecondFollowup);
  }

  function handleThirdFollowup() {
    setHasThirdFollowup(!hasThirdFollowup);
  }

  function handleFourthFollowup() {
    setHasFourthFollowup(!hasFourthFollowup);
  }

  function handleFifthFollowup() {
    setHasFifthFollowup(!hasFifthFollowup);
  }

  function handleSixthFollowup() {
    setHasSixthFollowup(!hasSixthFollowup);
  }

  function parseCopy(copy, index) {
    if (copy && copy.indexOf("{{") >= 0 && copy.indexOf("}}") >= 0) {
      const start_index = copy.indexOf("{{");
      const end_index = copy.indexOf("}}");
      const variable = copy.slice(start_index + 2, end_index);
      return parseCopy(
        copy.replace(
          copy.slice(start_index, end_index + 2),
          mappedContacts[index][variable]
        ),
        index
      );
    }
    return copy;
  }

  function addConnectionVariable(fieldName) {
    setConnectionRequest(connectionRequest + " {{" + fieldName.label + "}}");
  }

  function addFirstFollowupVariable(fieldName) {
    setFirstFollowUpText(firstFollowUpText + " {{" + fieldName.label + "}}");
  }

  function addSecondFollowupVariable(fieldName) {
    setSecondFollowUpText(secondFollowUpText + " {{" + fieldName.label + "}}");
  }

  function addThirdFollowupVariable(fieldName) {
    setThirdFollowUpText(thirdFollowUpText + " {{" + fieldName.label + "}}");
  }

  function addFourthFollowupVariable(fieldName) {
    setFourthFollowUpText(fourthFollowUpText + " {{" + fieldName.label + "}}");
  }

  function addFifthFollowupVariable(fieldName) {
    setFifthFollowUpText(fifthFollowUpText + " {{" + fieldName.label + "}}");
  }

  function addSixthFollowupVariable(fieldName) {
    setSixthFollowUpText(sixthFollowUpText + " {{" + fieldName.label + "}}");
  }

  function updateTemplate(template) {
    setSelectedTemplate(template);
    const chosen_template = templates.filter(
      (temp) => temp.value === template.value
    )[0];
    setConnectionRequest(chosen_template.connectionRequestText);
    setHasFirstFollowup(chosen_template.hasFirstFollowup);
    setFirstFollowUpDays(chosen_template.firstFollowUpDays);
    setFirstFollowUpText(chosen_template.firstFollowUpText);
    setHasSecondFollowup(chosen_template.hasSecondFollowup);
    setSecondFollowUpDays(chosen_template.secondFollowUpDays);
    setSecondFollowUpText(chosen_template.secondFollowUpText);
    setHasThirdFollowup(chosen_template.hasThirdFollowup);
    setThirdFollowUpDays(chosen_template.thirdFollowUpDays);
    setThirdFollowUpText(chosen_template.thirdFollowUpText);
    setHasFourthFollowup(chosen_template.hasFourthFollowup);
    setFourthFollowUpDays(chosen_template.fourthFollowUpDays);
    setFourthFollowUpText(chosen_template.fourthFollowUpText);
    setHasFifthFollowup(chosen_template.hasFifthFollowup);
    setFifthFollowUpDays(chosen_template.fifthFollowUpDays);
    setFifthFollowUpText(chosen_template.fifthFollowUpText);
    setHasSixthFollowup(chosen_template.hasSixthFollowup);
    setSixthFollowUpDays(chosen_template.sixthFollowUpDays);
    setSixthFollowUpText(chosen_template.sixthFollowUpText);
  }

  return (
    <div className="NewContacts">
      {isLoading ? (
        uploadComplete ? (
          <ViewCampaign
            changeScreen={props.props.props.props.changeScreen}
            campaignId={campaign.campaignId}
            props={props}
          />
        ) : isUploading ? (
          <Row>
            <Col sm={12}>
              <p style={{ opacity: 1, textAlign: "center" }}>
                <b>Uploading your contacts ...</b>
              </p>
              <p style={{ opacity: 1, textAlign: "center" }}>
                Don't touch anything - this will take a few minutes.
              </p>
              <img
                style={{ width: "100%" }}
                alt="loading-gif"
                src="https://conversify-marketing.s3.amazonaws.com/creating-account.gif"
              />
            </Col>
          </Row>
        ) : (
          <div>
            {finishedMapping ? (
              <div>
                <Row>
                  {!uploadReady ? (
                    <Col sm={12}>
                      <p className="text-left">Choose a Template </p>
                      <Dropdown
                        value={selectedTemplate}
                        options={templates}
                        onChange={updateTemplate}
                        placeholder="Select a template"
                      />
                      <span
                        className="other-btn pull-left"
                        onClick={() => setFinishedMapping(false)}
                      >
                        <MdArrowBack fontSize="16px" />
                        Back
                      </span>
                      <span
                        className="other-btn pull-right"
                        onClick={() => setUploadReady(true)}
                      >
                        Next
                        <MdArrowForward fontSize="16px" />
                      </span>
                    </Col>
                  ) : (
                    <Col sm={12}>
                      <p className="text-left">
                        <span
                          className="other-btn"
                          onClick={() => setUploadReady(false)}
                        >
                          <MdArrowBack fontSize="16px" />
                          Back
                        </span>
                      </p>
                      <br />
                      <p className="text-left">
                        Preview {campaign.title} for{" "}
                        {mappedContacts[previewIndex]["firstName"]}{" "}
                        <a
                          alt="link to linkedin profile"
                          target="_blank"
                          rel="noopener noreferrer"
                          href={mappedContacts[previewIndex]["linkedin"]}
                        >
                          <img
                            alt="linkedin logo"
                            height="16"
                            src="https://conversify-marketing.s3.amazonaws.com/linkedin.svg"
                          />
                        </a>
                      </p>
                      <hr />
                      {connectionRequest &&
                      parseCopy(connectionRequest, previewIndex).includes(
                        "undefined"
                      ) ? (
                        <div className="warning-label">
                          <p className="text-center">
                            Looks like one of your variables wasn't mapped
                            properly! You may have to go back and map your
                            variables again.
                          </p>
                        </div>
                      ) : null}
                      <br />
                      <div className="text-left">
                        <p>
                          <b>Connection Request</b>
                        </p>
                        {connectionRequest &&
                        parseCopy(connectionRequest, previewIndex).length ===
                          0 ? (
                          <p>
                            <i>
                              ***Connection Request will be sent without a note.
                            </i>
                          </p>
                        ) : (
                          <p>{parseCopy(connectionRequest, previewIndex)}</p>
                        )}
                        {hasFirstFollowup ? (
                          <div>
                            <p className="highlight  blue">
                              <br />
                              <small>wait {firstFollowUpDays} days</small>
                              <br />
                              <br />
                            </p>
                          </div>
                        ) : null}
                        {hasFirstFollowup ? (
                          <div>
                            <p>
                              <b>Follow up</b>
                            </p>
                            <p>{parseCopy(firstFollowUpText, previewIndex)}</p>
                          </div>
                        ) : null}
                        {hasSecondFollowup ? (
                          <div>
                            <p className="highlight blue">
                              <br />
                              <small>wait {secondFollowUpDays} days</small>
                              <br />
                              <br />
                            </p>
                          </div>
                        ) : null}
                        {hasSecondFollowup ? (
                          <div>
                            <p>
                              <b>Second Follow up</b>
                            </p>
                            <p>{parseCopy(secondFollowUpText, previewIndex)}</p>
                          </div>
                        ) : null}

                        {hasThirdFollowup ? (
                          <div>
                            <p className="highlight blue">
                              <br />
                              <small>wait {thirdFollowUpDays} days</small>
                              <br />
                              <br />
                            </p>
                          </div>
                        ) : null}
                        {hasThirdFollowup ? (
                          <div>
                            <p>
                              <b>Third Follow up</b>
                            </p>
                            <p>{parseCopy(thirdFollowUpText, previewIndex)}</p>
                          </div>
                        ) : null}

                        {hasFourthFollowup ? (
                          <div>
                            <p className="highlight blue">
                              <br />
                              <small>wait {fourthFollowUpDays} days</small>
                              <br />
                              <br />
                            </p>
                          </div>
                        ) : null}
                        {hasFourthFollowup ? (
                          <div>
                            <p>
                              <b>Fourth Follow up</b>
                            </p>
                            <p>{parseCopy(fourthFollowUpText, previewIndex)}</p>
                          </div>
                        ) : null}

                        {hasFifthFollowup ? (
                          <div>
                            <p className="highlight blue">
                              <br />
                              <small>wait {fifthFollowUpDays} days</small>
                              <br />
                              <br />
                            </p>
                          </div>
                        ) : null}
                        {hasFifthFollowup ? (
                          <div>
                            <p>
                              <b>Fifth Follow up</b>
                            </p>
                            <p>{parseCopy(fifthFollowUpText, previewIndex)}</p>
                          </div>
                        ) : null}

                        {hasSixthFollowup ? (
                          <div>
                            <p className="highlight blue">
                              <br />
                              <small>wait {sixthFollowUpDays} days</small>
                              <br />
                              <br />
                            </p>
                          </div>
                        ) : null}
                        {hasSixthFollowup ? (
                          <div>
                            <p>
                              <b>Sixth Follow up</b>
                            </p>
                            <p>{parseCopy(sixthFollowUpText, previewIndex)}</p>
                          </div>
                        ) : null}
                      </div>
                      {selectedTemplate ? (
                        <form onSubmit={handleSubmit}>
                          <div className="hidden">
                            <FormGroup controlId="connectionRequest">
                              <h5>Connection Request</h5>
                              <Dropdown
                                options={variables}
                                onChange={addConnectionVariable}
                                placeholder="variable"
                              />
                              <FormControl
                                value={connectionRequest}
                                componentClass="textarea"
                                className={
                                  connectionRequest &&
                                  connectionRequest.length > 300
                                    ? "red-border"
                                    : null
                                }
                                onChange={(e) =>
                                  setConnectionRequest(e.target.value)
                                }
                              />
                            </FormGroup>

                            <FormGroup controlId="hasFirstFollowup">
                              <h5>
                                Send Follow Up? &nbsp;
                                <input
                                  checked={hasFirstFollowup}
                                  onChange={handleFirstFollowup}
                                  type="checkbox"
                                />
                              </h5>
                            </FormGroup>
                            {hasFirstFollowup ? (
                              <div>
                                <FormGroup controlId="firstFollowUpDays">
                                  <h5>How many days after?</h5>
                                  <FormControl
                                    value={firstFollowUpDays}
                                    type="number"
                                    onChange={(e) =>
                                      setFirstFollowUpDays(e.target.value)
                                    }
                                  />
                                </FormGroup>
                                <FormGroup controlId="firstFollowUpText">
                                  <h5>Followup Message</h5>
                                  <Dropdown
                                    options={variables}
                                    onChange={addFirstFollowupVariable}
                                    placeholder="variable"
                                  />
                                  <FormControl
                                    value={firstFollowUpText}
                                    componentClass="textarea"
                                    onChange={(e) =>
                                      setFirstFollowUpText(e.target.value)
                                    }
                                  />
                                </FormGroup>
                                <FormGroup controlId="hasSecondFollowup">
                                  <h5>
                                    Send Another Follow Up? &nbsp;
                                    <input
                                      checked={hasSecondFollowup}
                                      onChange={handleSecondFollowup}
                                      type="checkbox"
                                    />
                                  </h5>
                                </FormGroup>
                              </div>
                            ) : null}

                            {hasSecondFollowup ? (
                              <div>
                                <FormGroup controlId="secondFollowUpDays">
                                  <h5>How many days after?</h5>
                                  <FormControl
                                    value={secondFollowUpDays}
                                    type="number"
                                    onChange={(e) =>
                                      setSecondFollowUpDays(e.target.value)
                                    }
                                  />
                                </FormGroup>
                                <FormGroup controlId="secondFollowUpText">
                                  <h5>Second Followup Message</h5>
                                  <Dropdown
                                    options={variables}
                                    onChange={addSecondFollowupVariable}
                                    placeholder="variable"
                                  />
                                  <FormControl
                                    value={secondFollowUpText}
                                    componentClass="textarea"
                                    onChange={(e) =>
                                      setSecondFollowUpText(e.target.value)
                                    }
                                  />
                                </FormGroup>
                                <FormGroup controlId="hasThirdFollowup">
                                  <h5>
                                    Send Another Follow Up? &nbsp;
                                    <input
                                      checked={hasThirdFollowup}
                                      onChange={handleThirdFollowup}
                                      type="checkbox"
                                    />
                                  </h5>
                                </FormGroup>
                              </div>
                            ) : null}

                            {hasThirdFollowup ? (
                              <div>
                                <FormGroup controlId="thirdFollowUpDays">
                                  <h5>How many days after?</h5>
                                  <FormControl
                                    value={thirdFollowUpDays}
                                    type="number"
                                    onChange={(e) =>
                                      setThirdFollowUpDays(e.target.value)
                                    }
                                  />
                                </FormGroup>
                                <FormGroup controlId="thirdFollowUpText">
                                  <h5>Third Followup Message</h5>
                                  <Dropdown
                                    options={variables}
                                    onChange={addThirdFollowupVariable}
                                    placeholder="variable"
                                  />
                                  <FormControl
                                    value={thirdFollowUpText}
                                    componentClass="textarea"
                                    onChange={(e) =>
                                      setThirdFollowUpText(e.target.value)
                                    }
                                  />
                                </FormGroup>
                                <FormGroup controlId="hasFourthFollowup">
                                  <h5>
                                    Send Another Follow Up? &nbsp;
                                    <input
                                      checked={hasFourthFollowup}
                                      onChange={handleFourthFollowup}
                                      type="checkbox"
                                    />
                                  </h5>
                                </FormGroup>
                              </div>
                            ) : null}

                            {hasFourthFollowup ? (
                              <div>
                                <FormGroup controlId="fourthFollowUpDays">
                                  <h5>How many days after?</h5>
                                  <FormControl
                                    value={fourthFollowUpDays}
                                    type="number"
                                    onChange={(e) =>
                                      setFourthFollowUpDays(e.target.value)
                                    }
                                  />
                                </FormGroup>
                                <FormGroup controlId="fourthFollowUpText">
                                  <h5>Fourth Followup Message</h5>
                                  <Dropdown
                                    options={variables}
                                    onChange={addFourthFollowupVariable}
                                    placeholder="variable"
                                  />
                                  <FormControl
                                    value={fourthFollowUpText}
                                    componentClass="textarea"
                                    onChange={(e) =>
                                      setFourthFollowUpText(e.target.value)
                                    }
                                  />
                                </FormGroup>
                                <FormGroup controlId="hasFifthFollowup">
                                  <h5>
                                    Send Another Follow Up? &nbsp;
                                    <input
                                      checked={hasFifthFollowup}
                                      onChange={handleFifthFollowup}
                                      type="checkbox"
                                    />
                                  </h5>
                                </FormGroup>
                              </div>
                            ) : null}

                            {hasFifthFollowup ? (
                              <div>
                                <FormGroup controlId="fifthFollowUpDays">
                                  <h5>How many days after?</h5>
                                  <FormControl
                                    value={fifthFollowUpDays}
                                    type="number"
                                    onChange={(e) =>
                                      setFifthFollowUpDays(e.target.value)
                                    }
                                  />
                                </FormGroup>
                                <FormGroup controlId="fifthFollowUpText">
                                  <h5>Fifth Followup Message</h5>
                                  <Dropdown
                                    options={variables}
                                    onChange={addFifthFollowupVariable}
                                    placeholder="variable"
                                  />
                                  <FormControl
                                    value={fifthFollowUpText}
                                    componentClass="textarea"
                                    onChange={(e) =>
                                      setFifthFollowUpText(e.target.value)
                                    }
                                  />
                                </FormGroup>
                                <FormGroup controlId="hasSixthFollowup">
                                  <h5>
                                    Send Another Follow Up? &nbsp;
                                    <input
                                      checked={hasSixthFollowup}
                                      onChange={handleSixthFollowup}
                                      type="checkbox"
                                    />
                                  </h5>
                                </FormGroup>
                              </div>
                            ) : null}

                            {hasSixthFollowup ? (
                              <div>
                                <FormGroup controlId="sixthFollowUpDays">
                                  <h5>How many days after?</h5>
                                  <FormControl
                                    value={sixthFollowUpDays}
                                    type="number"
                                    onChange={(e) =>
                                      setSixthFollowUpDays(e.target.value)
                                    }
                                  />
                                </FormGroup>
                                <FormGroup controlId="sixthFollowUpText">
                                  <h5>Sixth Followup Message</h5>
                                  <Dropdown
                                    options={variables}
                                    onChange={addSixthFollowupVariable}
                                    placeholder="variable"
                                  />
                                  <FormControl
                                    value={sixthFollowUpText}
                                    componentClass="textarea"
                                    onChange={(e) =>
                                      setSixthFollowUpText(e.target.value)
                                    }
                                  />
                                </FormGroup>
                              </div>
                            ) : null}
                          </div>
                          <hr />
                          <button
                            block
                            type="submit"
                            className="btn-secondary pull-left"
                            isLoading={!isLoading}
                            disabled={!validateForm()}
                          >
                            Upload Contacts
                          </button>
                        </form>
                      ) : null}
                    </Col>
                  )}
                </Row>
              </div>
            ) : contacts && contacts.length > 0 ? (
              <div>
                <Row>
                  <Col sm={12}>
                    <Row>
                      <Col sm={12}>
                        <p className="text-center">
                          Match columns in your CSV to variables you can use in
                          your template.
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={8} smOffset={2}>
                        <hr />
                      </Col>
                      <Col sm={8} smOffset={2}>
                        {contacts[0].map((header, fieldIndex) =>
                          header !== "fullName" ? (
                            <Row>
                              <Col xs={6} key={fieldIndex}>
                                <Dropdown
                                  value={
                                    matching.filter(
                                      (match) => match.fieldIndex === fieldIndex
                                    ).length === 1
                                      ? matching.filter(
                                          (match) =>
                                            match.fieldIndex === fieldIndex
                                        )[0]["fieldName"]
                                      : null
                                  }
                                  options={options}
                                  onChange={(fieldName) =>
                                    _onSelect(fieldIndex, fieldName)
                                  }
                                  placeholder="Match Variable"
                                />
                              </Col>
                              <Col xs={6}>
                                <h6>{header}</h6>
                              </Col>

                              <Col xs={12}>
                                <hr />
                              </Col>
                            </Row>
                          ) : null
                        )}
                      </Col>
                    </Row>

                    <Row>
                      <Col sm={12}>
                        <Button
                          disabled={!hasLinkedIn}
                          onClick={handleMatchingClick}
                          className="other-btn"
                        >
                          {hasLinkedIn
                            ? "Match Variables"
                            : "LinkedIn field is required"}
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            ) : (
              <Row>
                <Col style={{ paddingLeft: "0px" }} sm={6} smOffset={3}>
                  <p className="text-left">Upload contacts from a CSV</p>
                  <CSVReader
                    cssClass="react-csv-input pull-left"
                    onFileLoaded={handleForce}
                  />{" "}
                </Col>
              </Row>
            )}
          </div>
        )
      ) : (
        <IosRefresh fontSize="60px" color="#0085ef" rotate={true} />
      )}
    </div>
  );
}

const dev = {
  STRIPE_KEY: "pk_test_oahXrxRzZD0WBExM4OmhJILm0079haqrRk",
  s3: {
    REGION: "us-east-1",
    BUCKET: "conversify-api-dev-attachmentsbucket-p79zj1u57qs0",
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://ewvfbfhi8j.execute-api.us-east-1.amazonaws.com/dev",
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_nNpYaPF2w",
    APP_CLIENT_ID: "55c7r21su7j4v5lv7abct2d870",
    IDENTITY_POOL_ID: "us-east-1:3f842eda-e29f-4a96-a5d4-eca4fa77f83f",
  },
};

const prod = {
  STRIPE_KEY: "pk_live_xCywuGh1S6edC3uG6qSxWa0M00UGRwNbbY",
  s3: {
    REGION: "us-east-1",
    BUCKET: "conversify-api-prod-attachmentsbucket-1x24pslwb0lpu",
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://k7z2u6590c.execute-api.us-east-1.amazonaws.com/prod",
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_XVlxYqQ8i",
    APP_CLIENT_ID: "15gh2rsl2huqdjjd2f6a721i3a",
    IDENTITY_POOL_ID: "us-east-1:3da72353-94f9-472d-a276-ba8f6757dc1d",
  },
};

// Default to dev if not set
const config = process.env.NODE_ENV === "production" ? prod : dev;

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config,
};

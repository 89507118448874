import "./ViewAccount.css";

import { Col, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";

import { API } from "aws-amplify";
import Accounts from "./Accounts";
import Loading from "./Loading";

export default function ViewAccount(props) {
  const [account, setAccount] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    function loadAccount() {
      return API.get("accounts", `/accounts/${props.match.params.id}`);
    }

    async function onLoad() {
      try {
        const account = await loadAccount();
        setAccount(account);
        setIsLoading(true);
      } catch (e) {
        alert(e);
      }
    }

    onLoad();
  }, [props.match.params.id]);

  function deleteAccount() {
    return API.del("accounts", `/accounts/${account.accountId}`);
  }

  async function handleDelete(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      await deleteAccount();
      props.history.push(`/accounts/new`);
    } catch (e) {
      alert(e);
      setIsLoading(false);
    }
  }

  return (
    <div className="ViewAccount">
      {isLoading ? (
        account.firstName === "none" && account.lastName === "none" ? (
          <div>
            <br />
            <br />
            <div className="form-wrapper">
              <Row>
                <Col className="text-center">
                  <p style={{ opacity: 1, textAlign: "center" }}>
                    <b>
                      {" "}
                      <span role="img" aria-label="sad">
                        😞
                      </span>{" "}
                      Hmm ...{" "}
                    </b>
                  </p>

                  <p>
                    We had an issue verifying your login information for the
                    LinkedIn account with the email {account.email}.
                  </p>
                </Col>
              </Row>
              <br />
              <Row>
                <Col sm={12}>
                  <div className="">
                    <button
                      style={{
                        width: "100%",
                        display: "block",
                        marginTop: "0pxs",
                      }}
                      className="btn-secondary"
                      onClick={handleDelete}
                    >
                      Try again
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        ) : (
          <div>
            <Row>
              <Col sm={12} xs={12}>
                <Accounts accountId={props.match.params.id} props={props} />
              </Col>
            </Row>
          </div>
        )
      ) : (
        <Loading />
      )}
    </div>
  );
}

import "./NotFound.css";

import React from "react";

export default function NotFound() {
  return (
    <div className="NotFound">
      <div className="form-wrapper">
        <h5 className="text-center">Outreach, connect & engage on LinkedIn.</h5>
        <p className="text-center">
          InstantPipeline will skyrocket your productivity, book you more
          meetings, and help you crush your sales targets
        </p>
        <br />
        <p className="text-center">
          <a
            href="/dashboard"
            className="btn-secondary"
            style={{ margin: "auto" }}
          >
            Continue
          </a>
        </p>
      </div>
    </div>
  );
}

import "./Templates.css";

import { Col, Modal, Row, Table } from "react-bootstrap";
import React, { useEffect, useState } from "react";

import { API } from "aws-amplify";
import IosRefresh from "react-ionicons/lib/IosRefresh";
import MdAdd from "react-ionicons/lib/MdAdd";
import NewTemplate from "./NewTemplate";
import ViewTemplate from "./ViewTemplate";

export default function Templates(props) {
  const [templates, setTemplates] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [viewTemplateModel, setViewTemplateModel] = useState(false);
  const [templateId, setTemplateId] = useState(null);
  const [viewNewTemplateModel, setViewNewTemplateModel] = useState(false);

  useEffect(() => {
    async function onLoad() {
      try {
        const templates = await loadTemplates();
        setTemplates(templates);
      } catch (e) {
        alert(e);
      }

      setIsLoading(false);
    }

    onLoad();
  }, [props.isAuthenticated]);

  function loadTemplates() {
    return API.get("templates", "/templates");
  }

  async function closeTemplateModel() {
    setViewTemplateModel(false);
    const templates = await loadTemplates();
    console.log(templates);
    setTemplates(templates);
  }

  async function closeNewTemplateModel() {
    setViewNewTemplateModel(false);
    const templates = await loadTemplates();
    console.log(templates);
    setTemplates(templates);
  }

  function showTemplate(templateId) {
    setTemplateId(templateId);
    setViewTemplateModel(true);
  }

  return (
    <div className="Templates">
      {!isLoading ? (
        templates.length === 0 ? (
          <div className="text-center">
            <br />
            <br />
            <br />
            <br />
            <h5>Create a Template</h5>
            <p>
              A template is made up of one connection request message <br /> and
              up to two follow-up messages.
            </p>
            <p className="text-center">
              <button
                onClick={() => setViewNewTemplateModel(true)}
                className="btn-secondary"
              >
                Create Template
              </button>
            </p>
          </div>
        ) : (
          <Row>
            <Col sm={12}>
              <div className="titlePanel">
                <span
                  className="other-btn"
                  onClick={() => setViewNewTemplateModel(true)}
                >
                  <MdAdd fontSize="16px" />
                  Add Template
                </span>
              </div>
            </Col>
            <Col sm={12}>
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Contacts</th>
                    <th>Requests</th>
                    <th>Connections</th>
                    <th>Replies</th>
                    <th>Positive Replies</th>
                  </tr>
                </thead>
                <tbody>
                  {templates.map((template, i) => (
                    <tr key={template.templateId}>
                      <td
                        style={{ cursor: "pointer" }}
                        onClick={() => showTemplate(template.templateId)}
                      >
                        {template.title}
                      </td>
                      <td>{template.contacts}</td>
                      <td>
                        {template.requests}{" "}
                        {template.contacts > 0 && template.requests > 0 ? (
                          <small className="blue">
                            {(
                              (template.requests / template.contacts) *
                              100
                            ).toFixed(0)}
                            %
                          </small>
                        ) : null}{" "}
                      </td>
                      <td>
                        {template.connections}{" "}
                        {template.contacts > 0 && template.connections > 0 ? (
                          <small className="blue">
                            {(
                              (template.connections / template.contacts) *
                              100
                            ).toFixed(0)}
                            %
                          </small>
                        ) : null}{" "}
                      </td>
                      <td>
                        {template.conversations}{" "}
                        {template.contacts > 0 && template.conversations > 0 ? (
                          <small className="blue">
                            {(
                              (template.conversations / template.contacts) *
                              100
                            ).toFixed(0)}
                            %
                          </small>
                        ) : null}{" "}
                      </td>
                      <td>
                        {template.positives}{" "}
                        {template.contacts > 0 && template.positives > 0 ? (
                          <small className="blue">
                            {(
                              (template.positives / template.contacts) *
                              100
                            ).toFixed(0)}
                            %
                          </small>
                        ) : null}{" "}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        )
      ) : (
        <div className="container text-center">
          <br />
          <br />
          <br />
          <br />
          <IosRefresh fontSize="60px" color="#3EA9F5" rotate={true} />
        </div>
      )}
      <Modal show={viewNewTemplateModel} dialogClassName="modal-90w">
        <Modal.Body>
          <div className="modal-card text-center">
            <Row>
              <Col xs={12}>
                <div className="NewTemplate" style={{ paddingTop: "0px" }}>
                  <button
                    onClick={() => closeNewTemplateModel()}
                    className="other-btn"
                  >
                    close
                  </button>
                </div>
              </Col>
            </Row>
            <NewTemplate setScreen={props.setScreen} props={props} />
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={viewTemplateModel} dialogClassName="modal-90w">
        <Modal.Body>
          <div className="modal-card text-center">
            <ViewTemplate
              setScreen={props.setScreen}
              templateId={templateId}
              props={props}
              closeTemplateModel={closeTemplateModel}
            />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

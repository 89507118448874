import "./EditCampaign.css";

import { API } from "aws-amplify";
import { ControlLabel, FormControl, FormGroup } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import IosRefresh from "react-ionicons/lib/IosRefresh";
import LoaderButton from "../components/LoaderButton";

export default function EditCampaign(props) {
  const [title, setTitle] = useState("");
  const [dailyLimit, setDailyLimit] = useState("");
  const [paused, setPaused] = useState("");
  const [campaign, setCampaign] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    function loadCampaign() {
      return API.get("campaigns", `/campaigns/${props.campaignId}`);
    }

    async function onLoad() {
      try {
        const campaign = await loadCampaign();
        setCampaign(campaign);
        setTitle(campaign.title);
        setPaused(campaign.paused);
        setDailyLimit(parseInt(campaign.dailyLimit));
        setIsLoading(false);
      } catch (e) {
        alert(e);
      }
    }

    onLoad();
  }, [props.campaignId]);

  function validateForm() {
    return title.length > 0;
  }

  function saveCampaign(campaign) {
    return API.put("campaigns", `/campaigns/${props.campaignId}`, {
      body: campaign,
    });
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    campaign["title"] = title;
    campaign["paused"] = paused;
    campaign["dailyLimit"] = dailyLimit;

    try {
      await saveCampaign(campaign);
      setIsLoading(false);
    } catch (e) {
      alert(e);
      setIsLoading(false);
    }
  }
  return (
    <div className="EditCampaign">
      <div>
        {!isLoading ? (
          <form onSubmit={handleSubmit}>
            <FormGroup controlId="title">
              <ControlLabel>Title</ControlLabel>
              <FormControl
                value={title}
                type="text"
                onChange={(e) => setTitle(e.target.value)}
              />
            </FormGroup>
            <FormGroup controlId="dailyLimit">
              <ControlLabel>Daily Sending Limit</ControlLabel>
              <FormControl
                value={dailyLimit}
                type="number"
                onChange={(e) => setDailyLimit(parseInt(e.target.value))}
              />
            </FormGroup>

            <p className="text-right">
              <LoaderButton
                type="submit"
                className="other-btn"
                isLoading={isLoading}
                disabled={!validateForm()}
              >
                Update Campaign
              </LoaderButton>
            </p>
          </form>
        ) : (
          <IosRefresh fontSize="60px" color="#0085ef" rotate={true} />
        )}
      </div>
    </div>
  );
}

import "./Accounts.css";

import { Col, Modal, Row, Table } from "react-bootstrap";
import React, { useEffect, useState } from "react";

import { API } from "aws-amplify";
import Broadcast from "./Broadcast";
import Campaigns from "./Campaigns";
import Contacts from "./Contacts";
import Conversations from "./Conversations";
import IosArrowForward from "react-ionicons/lib/IosArrowForward";
import IosChatboxes from "react-ionicons/lib/IosChatboxes";
import IosFunnel from "react-ionicons/lib/IosFunnel";
import IosPaper from "react-ionicons/lib/IosPaper";
import IosSettings from "react-ionicons/lib/IosSettings";
import MdMegaphone from "react-ionicons/lib/MdMegaphone";
import NewAccount from "./NewAccount";
import Settings from "./Settings";
import Templates from "./Templates";
import ViewCampaign from "./ViewCampaign";
import moment from "moment";

export default function Accounts(props) {
  const [campaigns, setCampaigns] = useState([]);
  const [account, setAccount] = useState(null);
  const [screen, setScreen] = useState("home");
  const [viewAddAaccountModal, setViewAddAccountModal] = useState(false);
  const [viewPaymentModal, setViewPaymentModal] = useState(false);
  const [viewReferFriend, setViewReferFriend] = useState(false);
  const [viewCampaignModal, setViewCampaignModal] = useState(false);
  const [campaignId, setCampaignId] = useState(null);

  useEffect(() => {
    async function onLoad() {
      try {
        const accounts = await loadAccounts();
        const campaigns = await loadCampaigns();

        const account = accounts.filter(
          (account) => account.accountId === props.accountId
        )[0];
        setCampaigns(campaigns);
        setAccount(account);
      } catch (e) {
        alert(e);
      }
    }

    onLoad();
  }, [props.accountId]);

  function loadCampaigns() {
    return API.get("campaigns", "/campaigns");
  }

  function loadAccounts() {
    return API.get("accounts", "/accounts");
  }

  function deleteAccount() {
    return API.del("accounts", `/accounts/${account.accountId}`);
  }

  async function handleDelete(event) {
    event.preventDefault();

    const confirmed = window.confirm(
      "Are you sure you want to delete this account?"
    );

    if (!confirmed) {
      return;
    }

    try {
      await deleteAccount();
      props.props.history.push(`/`);
    } catch (e) {
      alert(e);
    }
  }

  function changeScreen(newScreen) {
    setViewPaymentModal(false);
    setViewAddAccountModal(false);
    setScreen(newScreen);
  }

  function showViewCampaignModal(campaignId) {
    setCampaignId(campaignId);
    setViewCampaignModal(true);
  }

  function renderAccounts() {
    return (
      <div className="Accounts">
        <Row>
          <Col sm={2}>
            <img
              height="30"
              alt="conversify logo"
              src="https://conversify-marketing.s3.amazonaws.com/instantpipeline.png"
            />
            <br />
            <br />
            <h4
              onClick={() => setScreen("templates")}
              className={screen === "templates" ? "active" : null}
            >
              <IosPaper
                fontSize="16px"
                color={screen === "templates" ? "#3EA9F5" : null}
              />
              Templates
            </h4>
            <h4
              onClick={() => setScreen("campaigns")}
              className={screen === "campaigns" ? "active" : null}
            >
              <IosFunnel
                fontSize="16px"
                color={screen === "campaigns" ? "#3EA9F5" : null}
              />
              Campaigns
            </h4>
            <h4
              onClick={() => setScreen("conversations")}
              className={screen === "conversations" ? "active" : null}
            >
              <IosChatboxes
                fontSize="16px"
                color={screen === "conversations" ? "#3EA9F5" : null}
              />
              Inbox
            </h4>
            <h4
              onClick={() => setScreen("broadcast")}
              className={screen === "broadcast" ? "active" : null}
            >
              <MdMegaphone
                fontSize="16px"
                color={screen === "broadcast" ? "#3EA9F5" : null}
              />
              Broadcast
            </h4>
            <h4
              onClick={() => setScreen("settings")}
              className={screen === "settings" ? "active" : null}
              style={{ marginTop: "0px" }}
            >
              <IosSettings
                fontSize="16px"
                color={screen === "settings" ? "#3EA9F5" : null}
              />
              Settings
            </h4>
          </Col>
          <Col sm={10}>
            <div className="cardComponent">
              {screen === "home" && account ? (
                campaigns.length > 0 ? (
                  <div>
                    <Table striped bordered hover responsive>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Date</th>
                          <th>Account</th>
                          <th>Sending Limit</th>
                          <th>Contacts</th>
                          <th>Requests</th>
                          <th>Connections</th>
                          <th>Replies</th>
                          <th>Positive Replies</th>
                        </tr>
                      </thead>
                      <tbody>
                        {campaigns
                          .sort((a, b) => b.createdAt - a.createdAt)
                          .map((campaign, i) => (
                            <tr key={campaign.campaignId}>
                              <td
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  showViewCampaignModal(campaign.campaignId)
                                }
                              >
                                {campaign.title}
                              </td>
                              <td>
                                {moment(campaign.createdAt).format("MMM Do")}
                              </td>
                              <td>{campaign.username}</td>
                              <td>{campaign.dailyLimit} </td>
                              <td>{campaign.contacts}</td>
                              <td>
                                {campaign.requests}{" "}
                                {campaign.contacts > 0 &&
                                campaign.requests > 0 ? (
                                  <small className="blue">
                                    {parseInt(
                                      (campaign.requests / campaign.contacts) *
                                        100
                                    )}
                                    %
                                  </small>
                                ) : null}
                              </td>
                              <td>
                                {campaign.connections}{" "}
                                {campaign.contacts > 0 &&
                                campaign.connections > 0 ? (
                                  <small className="blue">
                                    {parseInt(
                                      (campaign.connections /
                                        campaign.contacts) *
                                        100
                                    )}
                                    %
                                  </small>
                                ) : null}
                              </td>
                              <td>
                                {campaign.conversations}{" "}
                                {campaign.contacts > 0 &&
                                campaign.conversations ? (
                                  <small className="blue">
                                    {parseInt(
                                      (campaign.conversations /
                                        campaign.contacts) *
                                        100
                                    )}
                                    %
                                  </small>
                                ) : null}
                              </td>
                              <td>
                                {campaign.positives}{" "}
                                {campaign.contacts > 0 &&
                                campaign.positives > 0 ? (
                                  <small className="blue">
                                    {parseInt(
                                      (campaign.positives / campaign.contacts) *
                                        100
                                    )}
                                    %
                                  </small>
                                ) : null}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                ) : (
                  <Row>
                    <Col sm={12}>
                      <div className="home">
                        <h2>
                          Follow these steps to get started with{" "}
                          {account.firstName} {account.lastName}'s account
                        </h2>
                        <div
                          onClick={() => setScreen("templates")}
                          className="step"
                          style={{ borderTop: "1px solid #bdc3c7" }}
                        >
                          <p>
                            <IosArrowForward fontSize="16px" color="#3EA9F5" />{" "}
                            Create a template
                          </p>
                        </div>
                        <div
                          onClick={() => setScreen("campaigns")}
                          className="step"
                        >
                          <p>
                            <IosArrowForward fontSize="16px" color="#3EA9F5" />{" "}
                            Start a campaign
                          </p>
                        </div>
                        <div
                          onClick={() => setScreen("conversations")}
                          className="step"
                        >
                          <p>
                            <IosArrowForward fontSize="16px" color="#3EA9F5" />{" "}
                            Manage your inbox
                          </p>
                        </div>
                        <div
                          onClick={() => setScreen("broadcast")}
                          className="step"
                        >
                          <p>
                            <IosArrowForward fontSize="16px" color="#3EA9F5" />{" "}
                            Broadcast a message
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                )
              ) : null}

              {screen === "contacts" ? (
                <Contacts props={props} accountId={props.accountId} />
              ) : null}
              {screen === "campaigns" ? (
                <Campaigns
                  changeScreen={changeScreen}
                  props={props}
                  accountId={props.accountId}
                  campaigns={campaigns}
                />
              ) : null}
              {screen === "conversations" ? (
                <Conversations
                  campaigns={campaigns}
                  props={props}
                  accountId={props.accountId}
                />
              ) : null}
              {screen === "broadcast" ? (
                <Broadcast
                  campaigns={campaigns}
                  props={props}
                  accountId={props.accountId}
                />
              ) : null}
              {screen === "templates" ? (
                <Templates setScreen={setScreen} props={props} />
              ) : null}
              {screen === "settings" ? (
                <Row>
                  <Col sm={12}>
                    <div className="home">
                      <h2>Settings</h2>
                      <div
                        onClick={() =>
                          props.props.history.push(
                            `/accounts/${props.accountId}/edit`
                          )
                        }
                        className="step"
                        style={{ borderTop: "1px solid #bdc3c7" }}
                      >
                        <p>
                          <IosArrowForward fontSize="16px" color="#3EA9F5" />{" "}
                          Update LinkedIn password for {account.firstName}{" "}
                          {account.lastName}'s account
                        </p>
                      </div>
                      <div onClick={handleDelete} className="step">
                        <p>
                          <IosArrowForward fontSize="16px" color="#3EA9F5" />{" "}
                          Delete {account.firstName} {account.lastName}'s
                          account
                        </p>
                      </div>

                      <div
                        onClick={() => props.props.handleLogout()}
                        className="step"
                      >
                        <p>
                          <IosArrowForward fontSize="16px" color="#3EA9F5" />{" "}
                          Logout
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
              ) : null}
            </div>
          </Col>
        </Row>
        <Modal
          show={viewAddAaccountModal}
          onHide={() => setViewAddAccountModal(false)}
          dialogClassName="modal-90w"
        >
          <Modal.Body>
            <div className="modal-card text-center">
              <NewAccount
                changeScreen={changeScreen}
                history={props.props.history}
              />
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={viewPaymentModal}
          onHide={() => setViewPaymentModal(false)}
          dialogClassName="modal-90w"
        >
          <Modal.Body>
            <div className="modal-card text-center">
              <Settings setScreen={setScreen} props={props} />
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={viewReferFriend}
          onHide={() => setViewReferFriend(false)}
          dialogClassName="modal-90w"
        >
          <Modal.Body>
            <div className="modal-card text-center" style={{ padding: "20px" }}>
              <Row>
                <Col className="text-center">
                  <p style={{ opacity: 1, textAlign: "center" }}>
                    <b>Your Referral Link</b>
                  </p>
                  <p className="referLink">{`https://app.conversify.ai/r/${props.accountId}`}</p>
                  <p>
                    We'll refund you one month's payment for each customer you
                    refer.
                  </p>
                </Col>
              </Row>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={viewCampaignModal}
          onHide={() => setViewCampaignModal(false)}
          dialogClassName="modal-90w"
        >
          <Modal.Body>
            <div className="modal-card text-center">
              <ViewCampaign
                changeScreen={props.changeScreen}
                props={props}
                campaignId={campaignId}
              />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }

  return <div className="">{renderAccounts()}</div>;
}

import "./ViewTemplate.css";

import { Col, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";

import { API } from "aws-amplify";
import EditTemplate from "./EditTemplate";
import IosRefresh from "react-ionicons/lib/IosRefresh";
import MdCheckmark from "react-ionicons/lib/MdCheckmark";
import MdCreate from "react-ionicons/lib/MdCreate";
import MdTrash from "react-ionicons/lib/MdTrash";

export default function ViewTemplate(props) {
  const [template, setTemplate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);

  useEffect(() => {
    function loadTemplate() {
      return API.get("templates", `/templates/${props.templateId}`);
    }

    async function onLoad() {
      try {
        const template = await loadTemplate();

        setTemplate(template);
        setIsLoading(true);
      } catch (e) {
        alert(e);
      }
    }

    onLoad();
  }, [props.templateId]);

  function deleteTemplate() {
    return API.del("templates", `/templates/${props.templateId}`);
  }

  async function handleDelete(event) {
    event.preventDefault();

    const confirmed = window.confirm(
      "Are you sure you want to delete this template?"
    );

    if (!confirmed) {
      return;
    }

    setIsLoading(true);

    try {
      await deleteTemplate();
      setIsDeleted(true);
    } catch (e) {
      alert(e);
      setIsLoading(false);
    }
  }

  return (
    <div className="ViewTemplate text-left">
      {isLoading ? (
        showEdit ? (
          <EditTemplate
            setScreen={props.setScreen}
            templateId={props.templateId}
            props={props}
            closeTemplateModel={props.closeTemplateModel}
          />
        ) : (
          <Row>
            <Col sm={12}>
              <div>
                {isDeleted ? null : (
                  <div className="pull-left">
                    <span
                      onClick={() => setShowEdit(true)}
                      className="other-btn"
                      style={{ marginRight: "10px" }}
                    >
                      <MdCreate fontSize="14px" />
                      Edit
                    </span>
                    <span onClick={handleDelete} className="other-btn">
                      <MdTrash fontSize="14px" />
                      Delete
                    </span>
                  </div>
                )}

                <span
                  onClick={() => props.closeTemplateModel()}
                  className="other-btn pull-right"
                >
                  close
                </span>
                <br />
                <br />
              </div>
              {isDeleted ? (
                <div className="text-center">
                  <h1>
                    <MdCheckmark fontSize="60px" color="#1e88e5" />
                  </h1>
                  <p style={{ textAlign: "center" }}>Template deleted.</p>
                </div>
              ) : (
                <div>
                  <hr />
                  <br />
                  <p>
                    <b>Connection Request</b>
                  </p>
                  <p>{template.connectionRequestText}</p>
                  {template.hasFirstFollowup ? (
                    <p>
                      <br />
                      <small className="blue">
                        wait {template.firstFollowUpDays} days
                      </small>
                      <br />
                      <br />
                    </p>
                  ) : null}
                  {template.hasFirstFollowup ? (
                    <div>
                      <p>
                        <b>Followup</b>
                      </p>
                      <p>{template.firstFollowUpText}</p>
                    </div>
                  ) : null}
                  {template.hasSecondFollowup ? (
                    <p>
                      <br />
                      <small className="blue">
                        wait {template.secondFollowUpDays} days
                      </small>
                      <br />
                      <br />
                    </p>
                  ) : null}
                  {template.hasSecondFollowup ? (
                    <div>
                      <p>
                        <b>Second Followup</b>
                      </p>
                      <p>{template.secondFollowUpText}</p>
                    </div>
                  ) : null}
                  {template.hasThirdFollowup ? (
                    <p>
                      <br />
                      <small className="blue">
                        wait {template.thirdFollowUpDays} days
                      </small>
                      <br />
                      <br />
                    </p>
                  ) : null}
                  {template.hasThirdFollowup ? (
                    <div>
                      <p>
                        <b>Third Followup</b>
                      </p>
                      <p>{template.thirdFollowUpText}</p>
                    </div>
                  ) : null}
                  {template.hasFourthFollowup ? (
                    <p>
                      <br />
                      <small className="blue">
                        wait {template.fourthFollowUpDays} days
                      </small>
                      <br />
                      <br />
                    </p>
                  ) : null}
                  {template.hasFourthFollowup ? (
                    <div>
                      <p>
                        <b>Fourth Followup</b>
                      </p>
                      <p>{template.fourthFollowUpText}</p>
                    </div>
                  ) : null}
                  {template.hasFifthFollowup ? (
                    <p>
                      <br />
                      <small className="blue">
                        wait {template.fifthFollowUpDays} days
                      </small>
                      <br />
                      <br />
                    </p>
                  ) : null}
                  {template.hasFifthFollowup ? (
                    <div>
                      <p>
                        <b>Fifth Followup</b>
                      </p>
                      <p>{template.fifthFollowUpText}</p>
                    </div>
                  ) : null}
                  {template.hasSixthFollowup ? (
                    <p>
                      <br />
                      <small className="blue">
                        wait {template.sixthFollowUpDays} days
                      </small>
                      <br />
                      <br />
                    </p>
                  ) : null}
                  {template.hasSixthFollowup ? (
                    <div>
                      <p>
                        <b>Sixth Followup</b>
                      </p>
                      <p>{template.sixthFollowUpText}</p>
                    </div>
                  ) : null}
                </div>
              )}
            </Col>
          </Row>
        )
      ) : (
        <IosRefresh fontSize="60px" color="#1e88e5" rotate={true} />
      )}
    </div>
  );
}

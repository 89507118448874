import "./ViewCampaign.css";

import { Col, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";

import { API } from "aws-amplify";
import { CSVLink } from "react-csv";
import Contacts from "./Contacts";
import IosArrowForward from "react-ionicons/lib/IosArrowForward";
import IosRefresh from "react-ionicons/lib/IosRefresh";
import MdCheckmark from "react-ionicons/lib/MdCheckmark";
import MdPause from "react-ionicons/lib/MdPause";
import NewContacts from "./NewContacts";
import Switch from "react-switch";
import ViewTemplateItem from "./ViewTemplateItem";
import moment from "moment";

export default function ViewCampaign(props) {
  const [campaign, setCampaign] = useState(null);
  const [contacts, setContacts] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [requests, setRequests] = useState(null);
  const [connections, setConnections] = useState(null);
  const [replies, setReplies] = useState(null);
  const [uploadContacts, setUploadContacts] = useState(false);
  const [listContacts, setListContacts] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [viewContacts, setViewContacts] = useState(false);
  const [viewRequests, setViewRequests] = useState(false);
  const [viewConnections, setViewConnections] = useState(false);
  const [viewReplies, setViewReplies] = useState(false);

  useEffect(() => {
    function loadCampaign() {
      return API.get("campaigns", `/campaigns/${props.campaignId}`);
    }

    function loadContacts() {
      return API.get("contacts", `/campaigns/${props.campaignId}/contacts`);
    }

    async function onLoad() {
      try {
        const campaign = await loadCampaign();
        const contacts = await loadContacts();
        setCampaign(campaign);
        setContacts(contacts);

        setRequests(
          contacts.filter((contact) => contact.connectionRequestSent === true)
        );
        setConnections(
          contacts.filter((contact) => contact.isConnection === true)
        );
        setReplies(contacts.filter((contact) => contact.hasReplied === true));
        setIsPaused(campaign.paused);
        setIsLoading(true);
      } catch (e) {
        alert(e);
      }
    }

    onLoad();
  }, [props.campaignId]);

  function saveCampaign(campaign) {
    return API.put("campaigns", `/campaigns/${props.campaignId}`, {
      body: campaign,
    });
  }

  function saveContact(contactId, updatedContact) {
    return API.put("contacts", `/contacts/${contactId}`, {
      body: updatedContact,
    });
  }

  async function handleUpdateContact(contact) {
    const updatedContact = {
      hasReplied: !contact.hasReplied,
    };

    try {
      await saveContact(contact.contactId, updatedContact);
    } catch (e) {
      alert(e);
    }
  }

  async function handleActivateCampaign() {
    setIsLoading(true);

    const title = campaign.title;
    const dailyLimit = campaign.dailyLimit;
    const paused = !campaign.paused;
    const slug = campaign.slug;
    const accountId = campaign.accountId;
    const contacts = campaign.contacts;
    const updatedCampaign = {
      title,
      paused,
      dailyLimit,
      slug,
      accountId,
      contacts,
      username: campaign.username,
      campaignId: campaign.campaignId,
    };

    try {
      await saveCampaign(updatedCampaign);
      setIsPaused(!campaign.paused);
    } catch (e) {
      alert(e);
      setIsLoading(false);
    }
  }

  return (
    <div className="ViewCampaign">
      {isLoading ? (
        contacts.length === 0 ? (
          uploadContacts ? (
            <Row>
              <NewContacts campaignId={props.campaignId} props={props} />
            </Row>
          ) : listContacts ? (
            <Row>
              <Contacts
                accountId={props.props.accountId}
                campaignId={props.campaignId}
                props={props}
              />
            </Row>
          ) : (
            <div>
              <Row>
                <p style={{ paddingLeft: "15px" }} className="text-left">
                  <b>Upload a CSV of contacts</b>
                </p>
                <p style={{ paddingLeft: "15px" }} className="text-left">
                  <button
                    className="btn-secondary"
                    style={{ margin: "0px" }}
                    onClick={() => setUploadContacts(true)}
                  >
                    Upload contacts
                  </button>
                </p>
                <br />
                <p style={{ paddingLeft: "15px" }} className="text-left">
                  <b>Don't have a CSV of contacts?</b>
                </p>
                <div
                  style={{ borderTop: "1px solid #bdc3c7" }}
                  onClick={() => setListContacts(true)}
                  className="step"
                >
                  <h4 className="text-left">
                    <IosArrowForward fontSize="16px" color="#3EA9F5" /> Download
                    a CSV of contacts from a sales nav list
                  </h4>
                </div>
                <a
                  href="https://www.apollo.io/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <div className="step">
                    <h4 className="text-left">
                      <IosArrowForward fontSize="16px" color="#3EA9F5" />{" "}
                      Download a CSV of contacts from Apollo
                    </h4>
                  </div>
                </a>
                <a
                  href="https://www.seamless.ai/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <div className="step">
                    <h4 className="text-left">
                      <IosArrowForward fontSize="16px" color="#3EA9F5" />{" "}
                      Download a CSV of contacts from Seamless
                    </h4>
                  </div>
                </a>
                <a
                  href="https://www.leadfuze.com/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <div className="step">
                    <h4 className="text-left">
                      <IosArrowForward fontSize="16px" color="#3EA9F5" />{" "}
                      Download a CSV of contacts from LeadFuze
                    </h4>
                  </div>
                </a>
              </Row>
            </div>
          )
        ) : contacts.length > 0 && requests.length === 0 ? (
          <div>
            <Row>
              <Col sm={12}>
                {isPaused ? (
                  <div className="text-center">
                    <h1>
                      <MdPause fontSize="60px" color="#3EA9F5" />
                    </h1>
                    <p style={{ textAlign: "center" }}>
                      <b>{campaign.title} is paused</b>
                    </p>
                    <p style={{ textAlign: "center" }}>
                      Activate this campaign and we will send{" "}
                      {campaign.dailyLimit} connection requests daily.
                    </p>
                    <br />
                    <p style={{ textAlign: "center" }}>
                      <button
                        className="btn-secondary"
                        onClick={handleActivateCampaign}
                      >
                        Activate Campaign
                      </button>
                    </p>
                    <br />
                    <hr />
                    <br />
                    <p className="text-center">
                      <b>{contacts.length}</b> contacts uploaded <br />
                      <CSVLink
                        data={contacts}
                        filename={"contacts.csv"}
                        target="_blank"
                        className="other-btn"
                      >
                        export contacts
                      </CSVLink>
                    </p>
                    <br />
                  </div>
                ) : (
                  <div className="text-center">
                    <h1>
                      <MdCheckmark fontSize="60px" color="#3EA9F5" />
                    </h1>
                    <p style={{ textAlign: "center" }}>
                      <b>{campaign.title} is active</b>
                    </p>
                    <p style={{ textAlign: "center" }}>
                      We will send {campaign.dailyLimit} connection requests
                      daily starting tomorrow morning.
                    </p>
                    <br />
                    <p style={{ textAlign: "center" }}>
                      <button
                        className="btn-secondary"
                        onClick={handleActivateCampaign}
                      >
                        Pause Campaign
                      </button>
                    </p>
                    <br />
                    <hr />
                    <br />
                    <p className="text-center">
                      <b>{contacts.length}</b> contacts uploaded <br />
                      <CSVLink
                        data={contacts}
                        filename={"contacts.csv"}
                        target="_blank"
                        className="other-btn"
                      >
                        export contacts
                      </CSVLink>
                    </p>
                    <br />
                  </div>
                )}
              </Col>
            </Row>
          </div>
        ) : (
          <Row>
            <Col sm={12}>
              {contacts.length === 0 ? (
                <div>
                  <br />
                  <br />
                  <div>
                    <a
                      className="btn-primary"
                      alt="upload contacts"
                      href={`/campaigns/${props.campaignId}/contacts/new`}
                    >
                      Upload Contacts
                    </a>
                  </div>
                  <br />
                </div>
              ) : (
                <div>
                  <Row>
                    {!(
                      viewContacts ||
                      viewRequests ||
                      viewConnections ||
                      viewReplies
                    ) ? (
                      <Col xs={12}>
                        <p className="text-left">
                          <small>
                            last updated{" "}
                            {moment(campaign.lastAutomatedAt).format(
                              "MMM Do, h:mm:ss a"
                            )}
                          </small>
                        </p>
                        <Row>
                          <Col xs={3}>
                            <p className="text-left">
                              <button
                                className="other-btn"
                                onClick={() => setViewContacts(true)}
                              >
                                {contacts.length} contacts
                              </button>
                            </p>
                          </Col>
                          <Col xs={3}>
                            <p className="text-center">
                              <button
                                className="other-btn"
                                onClick={() => setViewRequests(true)}
                              >
                                {requests.length} requests
                              </button>
                            </p>
                          </Col>
                          <Col xs={3}>
                            <p className="text-center">
                              <button
                                className="other-btn"
                                onClick={() => setViewConnections(true)}
                              >
                                {connections.length} connections
                              </button>
                            </p>
                          </Col>
                          <Col xs={3}>
                            <p className="text-right">
                              <button
                                className="other-btn"
                                onClick={() => setViewReplies(true)}
                              >
                                {replies.length} replies
                              </button>
                            </p>
                          </Col>
                        </Row>
                      </Col>
                    ) : null}

                    <Col xs={12}>
                      {viewContacts ? (
                        <div>
                          <p className="text-left">
                            <button
                              className="other-btn"
                              onClick={() => setViewContacts(false)}
                            >
                              back
                            </button>
                          </p>
                          <br />
                          <p className="text-left">
                            <b>{contacts.length}</b> contacts uploaded{" "}
                            <CSVLink
                              data={contacts}
                              filename={"contacts.csv"}
                              target="_blank"
                              className="other-btn"
                            >
                              export contacts
                            </CSVLink>
                          </p>
                          <hr />
                          {contacts.map((contact) => (
                            <Row>
                              <Col xs={1}>
                                <p className="text-left">
                                  <Switch
                                    height={20}
                                    width={35}
                                    onChange={() =>
                                      handleUpdateContact(contact)
                                    }
                                    onColor={"#3EA9F5"}
                                    checked={contact.hasReplied}
                                  />{" "}
                                </p>
                              </Col>
                              <Col xs={10}>
                                <p className="text-left">
                                  <b
                                    style={{
                                      position: "relative",
                                      bottom: "1px",
                                      marginLeft: "10px",
                                    }}
                                  >
                                    {contact.firstName} {contact.lastName}{" "}
                                    <a
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      href={contact.linkedinUrl}
                                    >
                                      <img
                                        height="15px"
                                        alt="linekdin logo"
                                        style={{
                                          position: "relative",
                                          bottom: "1px",
                                        }}
                                        src="https://s3.amazonaws.com/adsgen/linkedin.svg"
                                      />
                                    </a>
                                  </b>
                                </p>
                              </Col>
                            </Row>
                          ))}
                        </div>
                      ) : null}
                      {viewRequests ? (
                        <div>
                          <p className="text-left">
                            <button
                              className="other-btn"
                              onClick={() => setViewRequests(false)}
                            >
                              back
                            </button>
                          </p>
                          <br />
                          <p className="text-left">
                            <b>{requests.length}</b> contacts have been sent a
                            connection request{" "}
                            <CSVLink
                              data={requests}
                              filename={"requests.csv"}
                              target="_blank"
                              className="other-btn"
                            >
                              export requests
                            </CSVLink>
                          </p>
                          <hr />
                          {requests.map((contact) => (
                            <Row>
                              <Col xs={1}>
                                <p className="text-left">
                                  <Switch
                                    height={20}
                                    width={35}
                                    onChange={() =>
                                      handleUpdateContact(contact)
                                    }
                                    onColor={"#3EA9F5"}
                                    checked={contact.hasReplied}
                                  />{" "}
                                </p>
                              </Col>
                              <Col xs={10}>
                                <p className="text-left">
                                  <b
                                    style={{
                                      position: "relative",
                                      bottom: "1px",
                                      marginLeft: "10px",
                                    }}
                                  >
                                    {contact.firstName} {contact.lastName}{" "}
                                    <a
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      href={contact.linkedinUrl}
                                    >
                                      <img
                                        height="15px"
                                        alt="linekdin logo"
                                        style={{
                                          position: "relative",
                                          bottom: "1px",
                                        }}
                                        src="https://s3.amazonaws.com/adsgen/linkedin.svg"
                                      />
                                    </a>
                                  </b>
                                </p>
                              </Col>
                            </Row>
                          ))}
                        </div>
                      ) : null}

                      {viewConnections ? (
                        <div>
                          <p className="text-left">
                            <button
                              className="other-btn"
                              onClick={() => setViewConnections(false)}
                            >
                              back
                            </button>
                          </p>
                          <br />
                          <p className="text-left">
                            <b>{connections.length}</b> contacts are now
                            connections{" "}
                            <CSVLink
                              data={connections}
                              filename={"connections.csv"}
                              target="_blank"
                              className="other-btn"
                            >
                              export connections
                            </CSVLink>
                          </p>
                          <hr />
                          {connections.map((contact) => (
                            <Row>
                              <Col xs={1}>
                                <p className="text-left">
                                  <Switch
                                    height={20}
                                    width={35}
                                    onChange={() =>
                                      handleUpdateContact(contact)
                                    }
                                    onColor={"#3EA9F5"}
                                    checked={contact.hasReplied}
                                  />{" "}
                                </p>
                              </Col>
                              <Col xs={10}>
                                <p className="text-left">
                                  <b
                                    style={{
                                      position: "relative",
                                      bottom: "1px",
                                      marginLeft: "10px",
                                    }}
                                  >
                                    {contact.firstName} {contact.lastName}{" "}
                                    <a
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      href={contact.linkedinUrl}
                                    >
                                      <img
                                        height="15px"
                                        alt="linekdin logo"
                                        style={{
                                          position: "relative",
                                          bottom: "1px",
                                        }}
                                        src="https://s3.amazonaws.com/adsgen/linkedin.svg"
                                      />
                                    </a>
                                  </b>
                                </p>
                              </Col>
                            </Row>
                          ))}
                        </div>
                      ) : null}

                      {viewReplies ? (
                        <div>
                          <p className="text-left">
                            <button
                              className="other-btn"
                              onClick={() => setViewReplies(false)}
                            >
                              back
                            </button>
                          </p>
                          <br />
                          <p className="text-left">
                            <b>{replies.length}</b> contacts have replied{" "}
                            <CSVLink
                              data={replies}
                              filename={"replies.csv"}
                              target="_blank"
                              className="other-btn"
                            >
                              export replies
                            </CSVLink>
                          </p>
                          <hr />
                          {replies.map((contact) => (
                            <Row>
                              <Col xs={1}>
                                <p className="text-left">
                                  <Switch
                                    height={20}
                                    width={35}
                                    onChange={() =>
                                      handleUpdateContact(contact)
                                    }
                                    onColor={"#3EA9F5"}
                                    checked={contact.hasReplied}
                                  />{" "}
                                </p>
                              </Col>
                              <Col xs={10}>
                                <p className="text-left">
                                  <b
                                    style={{
                                      position: "relative",
                                      bottom: "1px",
                                      marginLeft: "10px",
                                    }}
                                  >
                                    {contact.firstName} {contact.lastName}{" "}
                                    <a
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      href={contact.linkedinUrl}
                                    >
                                      <img
                                        height="15px"
                                        alt="linekdin logo"
                                        style={{
                                          position: "relative",
                                          bottom: "1px",
                                        }}
                                        src="https://s3.amazonaws.com/adsgen/linkedin.svg"
                                      />
                                    </a>
                                  </b>
                                </p>
                              </Col>
                            </Row>
                          ))}
                        </div>
                      ) : null}
                    </Col>
                  </Row>
                </div>
              )}
              {!(
                viewContacts ||
                viewRequests ||
                viewConnections ||
                viewReplies
              ) ? (
                <div>
                  <br />
                  <ViewTemplateItem templateId={contacts[0].templateId} />
                </div>
              ) : null}
            </Col>
          </Row>
        )
      ) : (
        <IosRefresh fontSize="60px" color="#3EA9F5" rotate={true} />
      )}
    </div>
  );
}

import "./NewCampaign.css";

import {
  Col,
  ControlLabel,
  FormControl,
  FormGroup,
  Row,
} from "react-bootstrap";
import React, { useEffect, useState } from "react";

import { API } from "aws-amplify";
import IosRefresh from "react-ionicons/lib/IosRefresh";
import MdArrowBack from "react-ionicons/lib/MdArrowBack";
import MdArrowForward from "react-ionicons/lib/MdArrowForward";
import Settings from "./Settings";
import ViewCampaign from "./ViewCampaign";

export default function NewCampaign(props) {
  const [title, setTitle] = useState("");
  const [dailyLimit, setDailyLimit] = useState(40);
  const [isLoading, setIsLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [account, setAccount] = useState(null);
  const [profile, setProfile] = useState(null);
  const [showUpgrade, setShowUpgrade] = useState(false);
  const [question, setQuestion] = useState("title");
  const [campaign, setCampaign] = useState(null);

  useEffect(() => {
    function loadAccount() {
      return API.get("accounts", `/accounts/${props.accountId}`);
    }

    function loadProfile(userId) {
      return API.get("profiles", `/myprofile/${userId}`);
    }

    async function onLoad() {
      try {
        const account = await loadAccount();
        setAccount(account);

        try {
          const profile = await loadProfile(account.userId);
          setProfile(profile);
        } catch (e) {
          console.log(e);
          console.log("cant load profile");
        }
        setIsPageLoading(false);
      } catch (e) {
        alert(e);
      }
    }

    onLoad();
  }, [props.accountId]);

  function validateForm() {
    return title.length > 3;
  }

  function handleTitleValidation() {
    return title.length > 3;
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsPageLoading(true);
    try {
      const campaign = await createCampaign({
        title,
        dailyLimit,
        accountId: props.accountId,
        profileId: profile.profileId,
      });
      setCampaign(campaign);
      setIsPageLoading(false);
    } catch (e) {
      alert(e);
      setIsLoading(false);
    }
  }

  function createCampaign(campaign) {
    return API.post("campaigns", "/campaigns", {
      body: campaign,
    });
  }

  return (
    <div className="NewCampaign">
      {isPageLoading ? (
        <IosRefresh fontSize="60px" color="#3EA9F5" rotate={true} />
      ) : profile.campaignCount >= 1 && !profile.paid ? (
        <Row>
          {showUpgrade ? (
            <Settings />
          ) : (
            <Col sm={12}>
              <p className="text-left">
                <b>Upgrade</b>
              </p>
              <p className="text-left">
                You'll have to upgrade if you want to create another campaign.
              </p>
              <p>
                <span
                  className="other-btn pull-left"
                  onClick={() => setShowUpgrade(true)}
                >
                  Continue
                  <MdArrowForward fontSize="16px" />
                </span>
              </p>
            </Col>
          )}
        </Row>
      ) : campaign ? (
        <ViewCampaign
          setScreen={props.setScreen}
          props={props}
          campaignId={campaign.campaignId}
        />
      ) : (
        <div>
          <form onSubmit={handleSubmit}>
            {question === "title" ? (
              <FormGroup controlId="title">
                <ControlLabel>
                  What is the name of your campaign?{" "}
                  <small>(for your reference later)</small>
                </ControlLabel>
                <FormControl
                  value={title}
                  type="text"
                  onChange={(e) => setTitle(e.target.value)}
                />
                <button
                  className="other-btn pull-right"
                  onClick={() => setQuestion("description")}
                  disabled={!handleTitleValidation()}
                >
                  Next
                  <MdArrowForward fontSize="16px" />
                </button>
                <br className="clearfix" />
              </FormGroup>
            ) : (
              <FormGroup controlId="dailyLimit">
                <ControlLabel>
                  How many connection requests should we send out on{" "}
                  {account.firstName} {account.lastName}'s behalf daily?{" "}
                  <small>
                    (we recommend less than 80 and you can update it anytime)
                  </small>
                </ControlLabel>
                <FormControl
                  value={dailyLimit}
                  type="number"
                  onChange={(e) => setDailyLimit(e.target.value)}
                />
                <span
                  className="other-btn pull-left"
                  onClick={() => setQuestion("title")}
                >
                  <MdArrowBack fontSize="16px" />
                  Back
                </span>
                <button
                  className="other-btn pull-right"
                  type="submit"
                  isLoading={isLoading}
                  disabled={!validateForm()}
                >
                  Create Campaign
                  <MdArrowForward fontSize="16px" />
                </button>
                <br className="clearfix" />
              </FormGroup>
            )}
          </form>
        </div>
      )}
    </div>
  );
}

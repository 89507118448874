import "./Accounts.css";

import React, { useEffect } from "react";

import { API } from "aws-amplify";

export default function Dashboard(props) {
  useEffect(() => {
    async function onLoad() {
      try {
        const accounts = await loadAccounts();

        if (accounts.length > 0) {
          props.history.push(`/accounts/${accounts[0].accountId}`);
        } else {
          props.history.push(`/accounts/new`);
        }
      } catch (e) {
        alert(e);
      }
    }

    onLoad();
  }, [props.history]);

  function loadAccounts() {
    return API.get("accounts", "/accounts");
  }

  return <div className=""></div>;
}

import "./EditAccount.css";

import {
  Col,
  ControlLabel,
  FormControl,
  FormGroup,
  Row,
} from "react-bootstrap";
import React, { useEffect, useState } from "react";

import { API } from "aws-amplify";
import LoaderButton from "../components/LoaderButton";
import axios from "axios";

export default function VerifyAccount(props) {
  const [emailPin, setEmailPin] = useState("");
  const [account, setAccount] = useState(null);
  const [tryAgain, setTryAgain] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    function loadAccount() {
      return API.get("accounts", `/accounts/${props.match.params.id}`);
    }

    function verifyAccount(account) {
      return axios.post("https://legacy.api.conversify.ai/enqueue", {
        campaignId: "",
        accountKey: {
          userId: account.userId,
          accountId: account.accountId,
        },
        options: {
          commandTimeout: 0,
        },
        commands: [{ action: "connectAccount" }],
      });
    }

    const pause = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    async function onLoad() {
      try {
        const account = await loadAccount();
        console.log(account);
        setAccount(account);
        await verifyAccount(account);

        setIsLoading(true);

        let loginError;
        do {
          await pause(60000); // wait 60s
          let checkAgain = await loadAccount(account);
          loginError = checkAgain.loginError;
          if (!loginError) {
            // we're good!
            return props.history.push("/dashboard");
          } else if (loginError === "pin.required") {
            // show UI to enter the pin, update account.pin to the pin *as a string*
            setIsLoading(false);
          } else if (loginError === "credentials.invalid") {
            setIsLoading(false);
            setTryAgain(true);
          } else {
            setIsLoading(false);
            setIsError(true);
          }
        } while (loginError);
      } catch (e) {
        setIsLoading(false);
        setIsError(true);
        console.log(e);
      }
    }

    onLoad();
  }, [props.match.params.id, props.history]);

  function validateForm() {
    return emailPin.trim().length === 6;
  }

  function saveAccount(account) {
    return API.put("accounts", `/updatepin/${props.match.params.id}`, {
      body: account,
    });
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    const pause = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    try {
      await saveAccount({
        pin: emailPin.trim(),
        accountId: account.accountId,
      });
      await pause(30000);
      props.history.push(`/accounts/${account.accountId}`);
    } catch (e) {
      alert(e);
      setIsLoading(false);
    }
  }

  function deleteAccount() {
    return API.del("accounts", `/accounts/${account.accountId}`);
  }

  async function handleDelete(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      await deleteAccount();
      props.history.push(`/accounts/new`);
    } catch (e) {
      alert(e);
      setIsLoading(false);
    }
  }

  return (
    <div className="VerifyAccount">
      {!isLoading ? (
        isError ? (
          <div>
            <br />
            <br />
            <div className="form-wrapper">
              <Row>
                <Col className="text-center">
                  <p style={{ opacity: 1, textAlign: "center" }}>
                    <b>
                      {" "}
                      <span role="img" aria-label="sad">
                        😞
                      </span>{" "}
                      Hmm ...{" "}
                    </b>
                  </p>

                  <p>We had an issue verifying your login information.</p>
                </Col>
              </Row>
              <br />
              <Row>
                <Col sm={12}>
                  <div className="">
                    <button
                      style={{
                        width: "100%",
                        display: "block",
                        marginTop: "0pxs",
                      }}
                      className="btn-secondary"
                      onClick={handleDelete}
                    >
                      Try again
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        ) : tryAgain ? (
          <div>
            <br />
            <br />
            <div className="form-wrapper">
              <Row>
                <Col sm={12} className="text-center">
                  <p
                    style={{ opacity: 1, textAlign: "center", margin: "auto" }}
                  >
                    <b>
                      {" "}
                      <span role="img" aria-label="sad">
                        😞
                      </span>{" "}
                      Hmm ...{" "}
                    </b>
                  </p>
                  <p>That's not the right LinkedIn email and password.</p>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <div className="">
                    <button
                      style={{
                        width: "100%",
                        display: "block",
                        marginTop: "0pxs",
                      }}
                      className="btn-secondary"
                      onClick={handleDelete}
                    >
                      Try again
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        ) : (
          <div>
            <br />
            <br />
            <div className="form-wrapper">
              <form onSubmit={handleSubmit}>
                <FormGroup controlId="lastName">
                  <p>Check your email for a 6-digit code from LinkedIn</p>
                  <ControlLabel>Verification Code</ControlLabel>
                  <FormControl
                    value={emailPin}
                    type="text"
                    onChange={(e) => setEmailPin(e.target.value)}
                  />
                </FormGroup>
                <LoaderButton
                  type="submit"
                  className="btn-secondary"
                  isLoading={isLoading}
                  disabled={!validateForm()}
                >
                  Save
                </LoaderButton>
              </form>
            </div>
          </div>
        )
      ) : (
        <div>
          <br />
          <br />
          <div className="form-wrapper text-center">
            <Row>
              <Col sm={12}>
                <p style={{ opacity: 1, textAlign: "center" }}>
                  <b>Verifying your account ...</b>
                </p>
                <p style={{ opacity: 1, textAlign: "center" }}>
                  Don't touch anything - this will take about a minute.
                </p>
                <img
                  style={{ width: "100%" }}
                  alt="loading-gif"
                  src="https://conversify-marketing.s3.amazonaws.com/creating-account_instantpipeline.gif"
                />
              </Col>
            </Row>
          </div>
        </div>
      )}
    </div>
  );
}

import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.min.css";
import "./Campaigns.css";

import { Col, Modal, Row, Table } from "react-bootstrap";
import React, { useEffect, useState } from "react";

import { API } from "aws-amplify";
import EditCampaign from "./EditCampaign";
import Loading from "./Loading";
import MdAdd from "react-ionicons/lib/MdAdd";
import MdCreate from "react-ionicons/lib/MdCreate";
import NewCampaign from "./NewCampaign";
import Switch from "react-switch";
import ViewCampaign from "./ViewCampaign";
import moment from "moment";

export default function Campaigns(props) {
  const [campaigns, setCampaigns] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [account, setAccount] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [viewNewCampaignModel, setViewNewCampaignModel] = useState(false);
  const [viewEditModal, setViewEditModal] = useState(false);
  const [viewCampaignModal, setViewCampaignModal] = useState(false);
  const [campaignId, setCampaignId] = useState(null);
  const [templates, setTemplates] = useState(null);

  useEffect(() => {
    async function onLoad() {
      try {
        const campaigns = await loadCampaigns();
        const accounts = await loadAccounts();
        const templates = await loadTemplates();
        setTemplates(templates);
        setCampaigns(
          campaigns.filter((campaign) => campaign.accountId === props.accountId)
        );
        setAccounts(accounts);
        setAccount(
          accounts.filter((account) => account.accountId === props.accountId)[0]
        );
      } catch (e) {
        alert(e);
      }

      setIsLoading(false);
    }

    onLoad();
  }, [props.isAuthenticated, props.history, props.accountId, props.campaigns]);

  function loadCampaigns() {
    return API.get("campaigns", "/campaigns");
  }

  function loadTemplates() {
    return API.get("templates", "/templates");
  }

  function loadAccounts() {
    return API.get("accounts", "/accounts");
  }

  function saveCampaign(campaign) {
    return API.put("campaigns", `/campaigns/${campaign.campaignId}`, {
      body: campaign,
    });
  }

  async function handleActivateCampaign(campaign) {
    const updatedCampaign = {
      title: campaign.title,
      dailyLimit: campaign.dailyLimit,
      paused: !campaign.paused,
      slug: campaign.slug,
      accountId: campaign.accountId,
      campaignId: campaign.campaignId,
      username: campaign.username,
      userId: campaign.userId,
    };

    try {
      await saveCampaign(updatedCampaign);
    } catch (e) {
      alert(e);
    }
  }

  function showEditModal(campaignId) {
    setCampaignId(campaignId);
    setViewEditModal(true);
  }

  function showViewCampaignModal(campaignId) {
    setCampaignId(campaignId);
    setViewCampaignModal(true);
  }

  async function closeModal() {
    const campaigns = await loadCampaigns();
    setCampaigns(
      campaigns.filter((campaign) => campaign.accountId === props.accountId)
    );
    setViewNewCampaignModel(false);
    setViewCampaignModal(false);
    setViewEditModal(false);
  }

  function renderCampaigns() {
    return (
      <div className="Campaigns">
        {accounts.length > 0 ? (
          <Row>
            <Col sm={12}>
              {campaigns.length > 0 ? (
                <div>
                  <Row>
                    <Col xs={4}>
                      <div className="titlePanel">
                        <span
                          className="other-btn"
                          onClick={() => setViewNewCampaignModel(true)}
                        >
                          <MdAdd fontSize="16px" />
                          New Campaign
                        </span>
                      </div>
                    </Col>
                  </Row>

                  <Table striped bordered hover responsive>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Date</th>
                        <th>Sending Limit</th>
                        <th>Contacts</th>
                        <th>Requests</th>
                        <th>Connections</th>
                        <th>Replies</th>
                        <th>Positive Replies</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {campaigns
                        .sort((a, b) => b.createdAt - a.createdAt)
                        .map((campaign, i) => (
                          <tr key={campaign.campaignId}>
                            <td
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                showViewCampaignModal(campaign.campaignId)
                              }
                            >
                              {campaign.title}
                            </td>
                            <td>
                              {moment(campaign.createdAt).format("MMM Do")}
                            </td>
                            <td>
                              {campaign.dailyLimit}{" "}
                              <span
                                onClick={() =>
                                  showEditModal(campaign.campaignId)
                                }
                                className="other-btn"
                              >
                                <MdCreate fontSize="14px" />
                                Update
                              </span>
                            </td>
                            <td>{campaign.contacts}</td>
                            <td>
                              {campaign.requests}{" "}
                              {campaign.contacts > 0 ? (
                                <small className="blue">
                                  {parseInt(
                                    (campaign.requests / campaign.contacts) *
                                    100
                                  )}
                                  %
                                </small>
                              ) : null}
                            </td>
                            <td>
                              {campaign.connections}{" "}
                              {campaign.contacts > 0 ? (
                                <small className="blue">
                                  {parseInt(
                                    (campaign.connections / campaign.contacts) *
                                    100
                                  )}
                                  %
                                </small>
                              ) : null}
                            </td>
                            <td>
                              {campaign.conversations}{" "}
                              {campaign.contacts > 0 ? (
                                <small className="blue">
                                  {parseInt(
                                    (campaign.conversations /
                                      campaign.contacts) *
                                    100
                                  )}
                                  %
                                </small>
                              ) : null}
                            </td>
                            <td>
                              {campaign.positives}{" "}
                              {campaign.contacts > 0 ? (
                                <small className="blue">
                                  {parseInt(
                                    (campaign.positives / campaign.contacts) *
                                    100
                                  )}
                                  %
                                </small>
                              ) : null}
                            </td>
                            <td>
                              {campaign.requests > 0 ? (
                                <Switch
                                  height={20}
                                  width={35}
                                  onChange={() =>
                                    handleActivateCampaign(campaign)
                                  }
                                  onColor={"#3EA9F5"}
                                  checked={!campaign.paused}
                                />
                              ) : (
                                  <span className="blue">
                                    {campaign.paused === true
                                      ? "Paused"
                                      : "Active"}
                                  </span>
                                )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </div>
              ) : templates.length > 0 ? (
                <div className="text-center">
                  <br />
                  <br />
                  <br />
                  <br />
                  <h5>Start a campaign</h5>
                  <p>
                    Create your first LinkedIn outreach campaign for{" "}
                    {account.firstName} {account.lastName}'s account
                  </p>
                  <p className="text-center">
                    <button
                      onClick={() => setViewNewCampaignModel(true)}
                      className="btn-secondary"
                    >
                      Create Campaign
                    </button>
                  </p>
                </div>
              ) : (
                    <div className="text-center">
                      <br />
                      <br />
                      <br />
                      <br />
                      <h5>Start a Campaign</h5>
                      <p>
                        You'll need to create a template before you create your{" "}
                        <br />
                    first campaign. Campaigns start the day after they are
                    created.
                  </p>
                      <p className="text-center">
                        <button
                          onClick={() => props.changeScreen("templates")}
                          className="btn-secondary"
                        >
                          Templates
                    </button>
                      </p>
                    </div>
                  )}
            </Col>
          </Row>
        ) : (
            props.history.push("/accounts/new")
          )}

        <Modal show={viewEditModal} dialogClassName="modal-90w">
          <Modal.Body>
            <div className="modal-card text-center">
              <button onClick={() => closeModal()} className="other-btn">
                close
              </button>
              <EditCampaign campaignId={campaignId} props={props} />
            </div>
          </Modal.Body>
        </Modal>

        <Modal show={viewNewCampaignModel} dialogClassName="modal-90w">
          <Modal.Body>
            <div className="modal-card text-center">
              <button onClick={() => closeModal()} className="other-btn">
                close
              </button>
              <br />
              <br />
              <Row>
                <NewCampaign
                  setScreen={props.setScreen}
                  props={props}
                  accountId={props.accountId}
                />
              </Row>
            </div>
          </Modal.Body>
        </Modal>

        <Modal show={viewCampaignModal} dialogClassName="modal-90w">
          <Modal.Body>
            <div
              className="modal-card text-center"
              style={{ paddingTop: "10px" }}
            >
              <button
                onClick={() => closeModal()}
                className="other-btn"
              >
                close
              </button>
              <br />
              <br />
              <ViewCampaign
                changeScreen={props.changeScreen}
                props={props}
                campaignId={campaignId}
              />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }

  return <div className="">{isLoading ? <Loading /> : renderCampaigns()}</div>;
}

import "./Broadcast.css";
import "react-dropdown/style.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.min.css";

import { Col, ControlLabel, FormControl, Modal, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";

import { API } from "aws-amplify";
import Dropdown from "react-dropdown";
import IosChatboxes from "react-ionicons/lib/IosChatboxes";
import Loading from "./Loading";
import MdCheckmark from "react-ionicons/lib/MdCheckmark";
import axios from "axios";
import moment from "moment";

export default function Contacts(props) {
  const [contacts, setContacts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [campaigns, setCampaigns] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [selectedContacts, setSelectedContacts] = useState(null);
  const [messageText, setMessageText] = useState("");
  const [messageLoad, setMessageLoad] = useState(false);
  const [coolContacts, setCoolContacts] = useState([]);
  const [viewBroadcastModel, setViewBroadcastModel] = useState(false);
  const [messageSent, setMessageSent] = useState(false);
  const [viewConversationModal, setViewConversationModal] = useState(false);
  const [contact, setContact] = useState(null);

  useEffect(() => {
    async function onLoad() {
      const userCampaigns = props.campaigns.filter(
        (campaign) =>
          campaign.accountId === props.accountId && campaign.conversations > 0
      );

      let contacts = [];

      if (userCampaigns.length > 0) {
        contacts = await loadContacts(userCampaigns[0].campaignId);
      }

      try {
        contacts = contacts.filter(
          (contact) =>
            "conversationMessages" in contact &&
            contact.conversationMessages.length > 2
        );
        const campaigns = [];
        for (let campaignIndex in userCampaigns) {
          const value = userCampaigns[campaignIndex].campaignId;
          const label = userCampaigns[campaignIndex].title;
          campaigns.push({
            label,
            value,
          });
        }
        if (campaigns.length > 0) {
          setCampaigns(campaigns);
          setSelectedCampaign(campaigns[0]);
          setContacts(contacts);
          setSelectedContacts(
            contacts.filter(
              (contact) =>
                contact.isConnection === true &&
                contact.campaignId === campaigns[0].value
            )
          );
        }
      } catch (e) {
        console.log(e);
      }

      setIsLoading(false);
    }

    onLoad();
  }, [props.isAuthenticated, props.accountId, props.campaigns]);

  function loadContacts(campaignId) {
    return API.get("contacts", `/campaigns/${campaignId}/contacts`);
  }

  async function sendMessage(currentContact) {
    try {
      await axios.post("https://legacy.api.conversify.ai/enqueue", {
        campaignId: currentContact.campaignId,
        accountKey: {
          accountId: currentContact.accountId,
          userId: currentContact.userId,
        },
        commands: [
          {
            action: "sendMessage",
            contactId: currentContact.contactId,
            params: { content: messageText },
          },
        ],
      });
    } catch (e) {
      console.log(e);
    }
  }

  function addContact(contactId) {
    if (coolContacts.includes(contactId)) {
      coolContacts.splice(coolContacts.indexOf(contactId), 1);
      setCoolContacts(coolContacts);
      setSelectedContacts(
        selectedContacts.filter(
          (contact) => !coolContacts.includes(contact.contactId)
        )
      );
    } else {
      coolContacts.push(contactId);
      setCoolContacts(coolContacts);
      setSelectedContacts(
        selectedContacts.filter(
          (contact) => !coolContacts.includes(contact.contactId)
        )
      );
    }
  }

  async function removeContact(contactId) {
    coolContacts.splice(coolContacts.indexOf(contactId), 1);
    setCoolContacts(coolContacts);
    let contacts = await loadContacts(selectedCampaign.value);
    setSelectedContacts(
      contacts.filter(
        (contact) =>
          !coolContacts.includes(contact.contactId) &&
          "conversationMessages" in contact &&
          contact.conversationMessages.length > 2
      )
    );
  }

  async function updateCampaign(campaign) {
    setSelectedCampaign(campaign);
    setCoolContacts([]);
    let contacts = await loadContacts(campaign.value);
    setContacts(contacts);
    setSelectedContacts(
      contacts.filter(
        (contact) =>
          "conversationMessages" in contact &&
          contact.conversationMessages.length > 2
      )
    );
  }

  function broadcastMessage() {
    setMessageLoad(true);
    for (let idx in coolContacts) {
      const contact = contacts.filter(
        (contact) => contact.contactId === coolContacts[idx]
      )[0];
      sendMessage(contact);
    }
    setCoolContacts([]);
    setMessageLoad(false);
    setMessageSent(true);
  }

  function finishBroadcast() {
    setViewBroadcastModel(false);
    setMessageSent(false);
    setMessageText("");
  }

  function showConversation(contact) {
    setContact(contact);
    setViewConversationModal(true);
  }

  return (
    <div className="Broadcast">
      {isLoading ? (
        <Loading />
      ) : contacts.length === 0 ? (
        <div>
          <Row>
            <Col sm={12} className="text-center">
              <br />
              <br />
              <br />
              <br />
              <h5>Broadcast a Message</h5>
              <p>
                Sending the same message to all your prospects takes forever.{" "}
                <br /> We'll show you how to quickly send a message to all your
                1st conneections.
              </p>
            </Col>
          </Row>
        </div>
      ) : (
        <div>
          <Row>
            <Col sm={3}>
              <Dropdown
                value={selectedCampaign}
                options={campaigns}
                onChange={updateCampaign}
                placeholder="Select a campaign"
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <div className="">
                {coolContacts.length > 0 ? (
                  <div>
                    <br />
                    <p className="text-left">
                      <button
                        className="btn-secondary pull-left"
                        onClick={() => setViewBroadcastModel(true)}
                        style={{ margin: "0px", marginLeft: "13px" }}
                      >
                        Broadcast message ({coolContacts.length})
                      </button>
                    </p>
                    <br />
                    <br />
                    {coolContacts.map((id) => {
                      const contact = contacts.filter(
                        (contact) => contact.contactId === id
                      )[0];
                      return (
                        <h4
                          key={id}
                          className={
                            contact && "conversationSentiment" in contact
                              ? contact.conversationSentiment
                              : null
                          }
                        >
                          <button
                            onClick={() => removeContact(contact.contactId)}
                            style={{
                              padding: "1px 10px",
                              display: "inline",
                              minWidth: "20px",
                              margin: "0px",
                            }}
                            className="btn-secondary"
                          >
                            -
                          </button>{" "}
                          <button
                            onClick={() => showConversation(contact)}
                            style={{
                              padding: "0px 10px",
                            }}
                            className="other-btn"
                          >
                            <IosChatboxes fontSize="13px" color={"#0b2e53"} />
                          </button>{" "}
                          <b>
                            {contact && contact.firstName}{" "}
                            {contact && contact.lastName !== "none"
                              ? contact.lastName
                              : null}{" "}
                          </b>{" "}
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={contact.linkedinUrl}
                          >
                            <img
                              height="15px"
                              alt="linekdin logo"
                              style={{ position: "relative", bottom: "1px" }}
                              src="https://s3.amazonaws.com/adsgen/linkedin.svg"
                            />
                          </a>{" "}
                          {contact && contact.linkedinHeadline}
                        </h4>
                      );
                    })}
                    <hr />
                  </div>
                ) : null}

                {selectedContacts.map((contact, i) =>
                  contact.fullName !== "none" ? (
                    <h4
                      key={i}
                      className={
                        contact && "conversationSentiment" in contact
                          ? contact.conversationSentiment
                          : null
                      }
                    >
                      <button
                        onClick={() => addContact(contact.contactId)}
                        style={{
                          padding: "1px 10px",
                          display: "inline",
                          minWidth: "20px",
                          margin: "0px",
                        }}
                        className="btn-secondary"
                      >
                        +
                      </button>{" "}
                      <button
                        onClick={() => showConversation(contact)}
                        style={{
                          padding: "0px 10px",
                        }}
                        className="other-btn"
                      >
                        <IosChatboxes fontSize="13px" color={"#0b2e53"} />
                      </button>{" "}
                      <b>
                        {contact.firstName}{" "}
                        {contact.lastName !== "none" ? contact.lastName : null}{" "}
                      </b>{" "}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={contact.linkedinUrl}
                      >
                        <img
                          height="15px"
                          alt="linekdin logo"
                          style={{ position: "relative", bottom: "1px" }}
                          src="https://s3.amazonaws.com/adsgen/linkedin.svg"
                        />
                      </a>{" "}
                      {contact.linkedinHeadline}
                    </h4>
                  ) : null
                )}
              </div>
            </Col>
          </Row>
        </div>
      )}
      <Modal show={viewBroadcastModel} dialogClassName="modal-90w">
        <Modal.Body>
          {messageSent ? (
            <div className="text-center">
              <button onClick={() => finishBroadcast()} className="other-btn">
                close
              </button>
              <br className="clearfix" />
              <h1>
                <MdCheckmark fontSize="60px" color="#3EA9F5" />
              </h1>
              <p style={{ textAlign: "center" }}>
                <b>Messsages Sent!</b>
              </p>
              <p style={{ textAlign: "center" }}>
                Check your LinkedIn accout to make sure the messages were sent.
              </p>
              <br className="clearfix" />
              <br className="clearfix" />
            </div>
          ) : !messageLoad ? (
            <div className="modal-card text-center">
              <button
                onClick={() => setViewBroadcastModel(false)}
                className="other-btn"
              >
                close
              </button>
              <br className="clearfix" />
              <ControlLabel>Message</ControlLabel>
              <FormControl
                value={messageText}
                componentClass="textarea"
                onChange={(e) => setMessageText(e.target.value)}
              />
              <br />
              <p className="text-left">
                <button
                  onClick={() => broadcastMessage()}
                  disabled={messageText.length < 2}
                  className="btn-secondary"
                >
                  Send Message
                </button>
              </p>
            </div>
          ) : (
            <div>
              <br />
              <br />
              <div className="text-center">
                <Row>
                  <Col sm={12}>
                    <p style={{ opacity: 1, textAlign: "center" }}>
                      <b>Broadcasting your message to selected contacts ...</b>
                    </p>
                    <p style={{ opacity: 1, textAlign: "center" }}>
                      Don't touch anything - this will take about a minute.
                    </p>
                    <img
                      style={{ width: "100%" }}
                      alt="loading-gif"
                      src="https://conversify-marketing.s3.amazonaws.com/creating-account_instantpipeline.gif"
                    />
                  </Col>
                </Row>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
      <Modal show={viewConversationModal} dialogClassName="modal-90w">
        <Modal.Body>
          <div className="modal-card text-left">
            <button
              onClick={() => setViewConversationModal(false)}
              className="other-btn"
            >
              close
            </button>
            <br />
            <br />
            {contact &&
              contact.conversationMessages.map((msg, i) => (
                <div key={i}>
                  <p>
                    <b>
                      {msg.user.firstName}{" "}
                      {msg.user.lastName !== "none" ? msg.user.lastName : null}{" "}
                    </b>
                    <small>
                      {moment(msg.createdAt).format("MMM Do, h:mm:ss a")}
                    </small>
                  </p>
                  <p>{msg.content}</p>
                  <br />
                </div>
              ))}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
